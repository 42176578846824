import React from "react";
import Back from "../../../../../assets/Back";

export default function ArrowMove({ direction, onClick, disabled }) {
  const style = {
    transform:
      direction === "left" || !direction ? "rotate(0deg)" : "rotate(-180deg)",
    cursor: !disabled ? "pointer" : "arrow",
  };
  return (
    <div style={style} onClick={!disabled ? onClick : undefined}>
      <Back />
    </div>
  );
}
