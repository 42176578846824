import React from "react";

export default function TextField({
  title,
  value,
  onChange,
  placeHolder,
  error,
  style,
  inputStyle,
  size,
  type,
  autoComplete,
}) {
  const containerStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    ...style,
  };
  const textInputStyle = {
    flex: 1,
    borderRadius: 6,
    borderWidth: 0,
    fontSize: 16,
    // fontWeight: "bold",
    padding: 8,
    backgroundColor: "#FCF8DB",
    color: "#636466",
    fontFamily: "arialRounded",
    ...inputStyle,
  };

  return (
    <div style={containerStyle}>
      {title && <div style={{ marginBottom: 3 }}>{title}</div>}
      <input
        style={textInputStyle}
        type={type ? type : "text"}
        value={value}
        placeholder={placeHolder}
        onChange={onChange}
        size={size}
        autoComplete={autoComplete}
      />
      <div
        style={{
          marginTop: 3,
          fontSize: 12,
          fontStyle: "italic",
          color: "red",
        }}
      >
        {error && error}
      </div>
    </div>
  );
}
