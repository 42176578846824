import React from "react";
import ContextualMenuItem from "./ContextualMenuItem";

export default function ContextualMenu({ items, closeContextualMenu }) {
  //const height = 33 + (42 * items.length)
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 10000,
        backgroundColor: "white",
        width: 250,
        right: 0,
        top: 0,
        borderRadius: 10,
        boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
      }}
    >
      <div
        onClick={() => closeContextualMenu()}
        style={{
          cursor: "pointer",
          padding: 8,
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#636466",
          color: "white",
          fontSize: 14,
        }}
      >
        Avbryt
      </div>
      <div style={{ height: 1, backgroundColor: "rgba(248,247,252,1)" }} />
      {items.map((item, index) => {
        return (
          <div key={item.content._id + index}>
            <ContextualMenuItem
              item={item}
              closeContextualMenu={closeContextualMenu}
            />
            {items.length > index + 1 && (
              <div
                style={{ height: 1, backgroundColor: "rgba(248,247,252,1)" }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
