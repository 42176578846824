import axios from "axios";
import {
  SET_PRESCHOOL_WEEKLY_PLANNING,
  SET_PRESCHOOL_EVENTS,
  SET_PRESCHOOL_GOALS,
  SET_PRESCHOOL_EMPLOYEES,
} from "./types";

export const getPreschoolWeeklyPLanning = (
  preschoolId,
  sectionId,
  dateFrom,
  dateTo
) => (dispatch) => {
  axios
    .get(
      `v2/plannedactivities/list/${preschoolId}/${sectionId}/${dateFrom}/${dateTo}`
    )
    .then((res) => {
      dispatch({
        type: SET_PRESCHOOL_WEEKLY_PLANNING,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err.response.data));
};

export const getPreschoolEvents = (preschoolId, dateFrom, dateTo) => (
  dispatch
) => {
  axios
    .get(`v2/reminders/list/range/${preschoolId}/${dateFrom}/${dateTo}`)
    .then((res) =>
      dispatch({
        type: SET_PRESCHOOL_EVENTS,
        payload: res.data,
      })
    )
    .catch((err) => console.log(err));
};

export const getPreschoolGoals = (preschoolId, date) => (dispatch) => {
  axios
    .get(`/v2/pedagogy/preschoolgoals/preschool/${preschoolId}/${date}`)
    .then((res) => dispatch({ type: SET_PRESCHOOL_GOALS, payload: res.data }))
    .catch((err) => console.log(err));
};

export const getPreschoolEmployees = (preschoolId, dateFrom, dateTo) => (
  dispatch
) => {
  dispatch({
    type: SET_PRESCHOOL_EMPLOYEES,
    payload: null,
  });
  axios
    .get(`/v2/employement/list/${preschoolId}/${dateFrom}/${dateTo}`)
    .then((res) => {
      dispatch({
        type: SET_PRESCHOOL_EMPLOYEES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err.response));
};
