import React from "react";

export default function Logout() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 18.375V20.5625C16.625 21.1427 16.3945 21.6991 15.9843 22.1093C15.5741 22.5195 15.0177 22.75 14.4375 22.75H5.6875C5.10734 22.75 4.55094 22.5195 4.1407 22.1093C3.73047 21.6991 3.5 21.1427 3.5 20.5625V7.4375C3.5 6.85734 3.73047 6.30094 4.1407 5.8907C4.55094 5.48047 5.10734 5.25 5.6875 5.25H14C15.208 5.25 16.625 6.22945 16.625 7.4375V9.625"
        stroke="#636466"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.125 18.375L24.5 14L20.125 9.625"
        stroke="#636466"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.625 14H23.625"
        stroke="#636466"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
