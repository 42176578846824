import React, { useState } from "react";
import CreateGoalComponent from "../createGoalComponent/CreateGoalComponent";
import PreschoolGoal from "../container/PreschoolGoal";

export default function PreschoolGoalList({ preschoolGoals }) {
  const [goalToEdit, setGoalToEdit] = useState(null);

  const onDeleteGoal = (goal) => {
    console.log(goal);
  };

  const onEditGoal = (goal) => {
    setGoalToEdit(goal);
    window.scrollTo(0, 0);
  };

  const onDoneEditing = () => {
    setGoalToEdit(null);
  };

  return (
    <div>
      {" "}
      <CreateGoalComponent goal={goalToEdit} />
      <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 15 }}>
        Våra mål
      </div>
      {preschoolGoals.map((goal) => {
        return (
          <PreschoolGoal
            goal={goal}
            style={{ marginBottom: 15 }}
            key={`${goal._id}list`}
            onDelete={onDeleteGoal}
            onEdit={onEditGoal}
            onDoneEditing={() => onDoneEditing()}
          />
        );
      })}
    </div>
  );
}
