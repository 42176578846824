import React from "react";

export function IconCalender({ size }) {
  return (
    <svg
      width={size ? size : 45}
      height={size ? size : 45}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.875 8.4375H10.125C7.32906 8.4375 5.0625 10.7041 5.0625 13.5V43.875C5.0625 46.6709 7.32906 48.9375 10.125 48.9375H43.875C46.6709 48.9375 48.9375 46.6709 48.9375 43.875V13.5C48.9375 10.7041 46.6709 8.4375 43.875 8.4375Z"
        stroke="#636466"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M41.9576 8.4375H12.0424C8.19387 8.4375 5.0625 11.6174 5.0625 15.525V21.9375H6.75C6.75 20.25 8.4375 18.5625 10.125 18.5625H43.875C45.5625 18.5625 47.25 20.25 47.25 21.9375H48.9375V15.525C48.9375 11.6174 45.8061 8.4375 41.9576 8.4375Z"
        fill="#636466"
      />
      <path
        d="M32.25 24.4688C32.25 25.0383 31.7883 25.5 31.2188 25.5C30.6492 25.5 30.1875 25.0383 30.1875 24.4688C30.1875 23.8992 30.6492 23.4375 31.2188 23.4375C31.7883 23.4375 32.25 23.8992 32.25 24.4688Z"
        stroke="#FDB913"
        strokeWidth="3"
      />
      <path
        d="M40.6875 24.4688C40.6875 25.0383 40.2258 25.5 39.6562 25.5C39.0867 25.5 38.625 25.0383 38.625 24.4688C38.625 23.8992 39.0867 23.4375 39.6562 23.4375C40.2258 23.4375 40.6875 23.8992 40.6875 24.4688Z"
        stroke="#FDB913"
        strokeWidth="3"
      />
      <path
        d="M32.25 32.9062C32.25 33.4758 31.7883 33.9375 31.2188 33.9375C30.6492 33.9375 30.1875 33.4758 30.1875 32.9062C30.1875 32.3367 30.6492 31.875 31.2188 31.875C31.7883 31.875 32.25 32.3367 32.25 32.9062Z"
        stroke="#FDB913"
        strokeWidth="3"
      />
      <path
        d="M40.6875 32.9062C40.6875 33.4758 40.2258 33.9375 39.6562 33.9375C39.0867 33.9375 38.625 33.4758 38.625 32.9062C38.625 32.3367 39.0867 31.875 39.6562 31.875C40.2258 31.875 40.6875 32.3367 40.6875 32.9062Z"
        stroke="#FDB913"
        strokeWidth="3"
      />
      <path
        d="M15.375 32.9062C15.375 33.4758 14.9133 33.9375 14.3438 33.9375C13.7742 33.9375 13.3125 33.4758 13.3125 32.9062C13.3125 32.3367 13.7742 31.875 14.3438 31.875C14.9133 31.875 15.375 32.3367 15.375 32.9062Z"
        stroke="#FDB913"
        strokeWidth="3"
      />
      <path
        d="M23.8125 32.9062C23.8125 33.4758 23.3508 33.9375 22.7812 33.9375C22.2117 33.9375 21.75 33.4758 21.75 32.9062C21.75 32.3367 22.2117 31.875 22.7812 31.875C23.3508 31.875 23.8125 32.3367 23.8125 32.9062Z"
        stroke="#FDB913"
        strokeWidth="3"
      />
      <path
        d="M15.375 41.3438C15.375 41.9133 14.9133 42.375 14.3438 42.375C13.7742 42.375 13.3125 41.9133 13.3125 41.3438C13.3125 40.7742 13.7742 40.3125 14.3438 40.3125C14.9133 40.3125 15.375 40.7742 15.375 41.3438Z"
        stroke="#FDB913"
        strokeWidth="3"
      />
      <path
        d="M23.8125 41.3438C23.8125 41.9133 23.3508 42.375 22.7812 42.375C22.2117 42.375 21.75 41.9133 21.75 41.3438C21.75 40.7742 22.2117 40.3125 22.7812 40.3125C23.3508 40.3125 23.8125 40.7742 23.8125 41.3438Z"
        stroke="#FDB913"
        strokeWidth="3"
      />
      <path
        d="M32.25 41.3438C32.25 41.9133 31.7883 42.375 31.2188 42.375C30.6492 42.375 30.1875 41.9133 30.1875 41.3438C30.1875 40.7742 30.6492 40.3125 31.2188 40.3125C31.7883 40.3125 32.25 40.7742 32.25 41.3438Z"
        stroke="#FDB913"
        strokeWidth="3"
      />
      <path
        d="M13.5 5.0625V8.4375"
        stroke="#636466"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5 5.0625V8.4375"
        stroke="#636466"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
