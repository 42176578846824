import React, { useState, useEffect } from "react";
import PrinterIcon from "../../../../../assets/printerIcon";

export default function PrinterButton({ onClick }) {
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const SIZE = 56;
  const BORDERWIDTH = 2;
  return (
    <div
      style={{
        display: "none",
        //display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: SIZE,
        height: SIZE,
        position: "fixed",
        backgroundColor: "white",
        borderWidth: 2,
        border: "solid",
        borderColor: "#FCB817",
        borderRadius: (SIZE + BORDERWIDTH * 2) / 2,
        bottom: 10,
        left: width > 600 ? width / 2 + 300 - 56 - 10 - 4 : width - 56 - 10 - 4,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <PrinterIcon />
    </div>
  );
}
