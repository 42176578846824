import React, { useEffect, useLayoutEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useSelector, useDispatch } from "react-redux";
import {
  getPedagogicalAreas,
  getUserAuthorizations,
  getPreschoolWeeklyPLanning,
  getPreschoolEvents,
  getPreschoolTrends,
  getPreschoolDocumentedActivities,
  getPreschoolGoalsStats,
  getPreschoolGoals,
  getPreschoolEmployees,
  getRoles,
  getPreschoolEvaluations,
} from "../store/actions";
import moment from "moment";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

//Public route components
import OurPreschool from "../components/pages/private/OurPreschool";
//import { ActivitiesCalender } from "../components/pages/private/activitiesCalender/activitiesCalender";

import PrivateHome from "../components/pages/private/PrivateHome";
import Profile from "../components/pages/private/Profile";
import Notifications from "../components/pages/private/Notifications";
import Reports from "../components/pages/private/Reports";
import PedagogicalareasReport from "../components/pages/private/reports/Pedagogicalareas";
import DocumentedActivitiesReport from "../components/pages/private/reports/DocumentedActivities";
import Events from "../components/pages/private/Events";
import PlannedActivities from "../components/pages/private/PlannedActivities";
import Analyse from "../components/pages/private/reports/Analyse";
import Reflections from "../components/pages/private/reports/Reflections";
import About from "../components/pages/private/About";

//Teams app routes
import Configure from "../components/pages/Configure";
import TeamsLogin from "../components/pages/teams/Login";

import Login from "../components/pages/public/Login";
import NotFoundPage from "../components/pages/NotFound";

export const history = createBrowserHistory();

const backgroundImage = {
  display: "flex",
  justifyContent: "center",
  backgroundImage: `url(${require("../assets/background.png")})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100vw",
  height: "100vh",
  color: "#636466",
  fontFamily: "arialRounded",
};

const AppRouter = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, userAuthorization } = useSelector(
    (state) => state.auth
  );
  const {
    selectedPreschool,
    eventsDateSpan,
    plannedActivitiesDateSpan,
    employementsDateSpan,
    reportDateSpan,
    currentAcademicYear,
  } = useSelector((state) => state.siteVar);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserAuthorizations());
      dispatch(getPedagogicalAreas());
      dispatch(getRoles());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (userAuthorization.length > 0 && selectedPreschool) {
      dispatch(
        getPreschoolWeeklyPLanning(
          selectedPreschool._id,
          selectedPreschool.sections[0]._id,
          plannedActivitiesDateSpan.dateFrom,
          plannedActivitiesDateSpan.dateTo
        )
      );
      dispatch(
        getPreschoolDocumentedActivities(
          selectedPreschool._id,
          selectedPreschool.sections[0]._id,
          moment(currentAcademicYear.startDate).format("YYYY-MM-DD"),
          reportDateSpan.dateTo
        )
      );
      dispatch(getPreschoolGoalsStats(selectedPreschool._id));
      dispatch(
        getPreschoolEvaluations(
          selectedPreschool._id,
          moment(currentAcademicYear.startDate).format("YYYY-MM-DD"),
          moment(currentAcademicYear.endDate).format("YYYY-MM-DD")
        )
      );
    }
  }, [
    userAuthorization,
    selectedPreschool,
    plannedActivitiesDateSpan,
    currentAcademicYear,
    dispatch,
  ]);

  useEffect(() => {
    if (userAuthorization.length > 0 && selectedPreschool && eventsDateSpan) {
      dispatch(
        getPreschoolEvents(
          selectedPreschool._id,
          eventsDateSpan.dateFrom,
          eventsDateSpan.dateTo
        )
      );
    }
  }, [userAuthorization, selectedPreschool, eventsDateSpan]);

  useEffect(() => {
    if (selectedPreschool) {
      dispatch(
        getPreschoolEmployees(
          selectedPreschool._id,
          employementsDateSpan.dateFrom,
          employementsDateSpan.dateTo
        )
      );
      dispatch(
        getPreschoolGoals(selectedPreschool._id, moment().format("YYYY-MM-DD"))
      );
    }
  }, [selectedPreschool, dispatch]);

  useEffect(() => {
    if (selectedPreschool) {
      dispatch(
        getPreschoolTrends(
          selectedPreschool._id,
          reportDateSpan.dateFrom,
          reportDateSpan.dateTo
        )
      );
      dispatch(
        getPreschoolDocumentedActivities(
          selectedPreschool._id,
          selectedPreschool.sections[0]._id,
          moment(currentAcademicYear.startDate).format("YYYY-MM-DD"),
          moment(reportDateSpan.dateTo).format("YYYY-MM-DD")
        )
      );
    }
  }, [selectedPreschool, reportDateSpan, currentAcademicYear, dispatch]);

  return (
    <Router history={history}>
      <div className="landing">
        <div style={backgroundImage}>
          <Switch>
            <PublicRoute path="/" component={Login} exact={true} />
            <Route path="/configure" component={Configure} exact />
            <Route path="/teams/login" component={TeamsLogin} exact />
            <PublicRoute path="/login" component={Login} exact />
            <PrivateRoute exact path="/dashboard" component={PrivateHome} />
            <PrivateRoute exact path="/events" component={Events} />
            <PrivateRoute
              exact
              path="/plannedactivities"
              component={PlannedActivities}
            />
            <PrivateRoute exact path="/reports" component={Reports} />
            <PrivateRoute
              exact
              path="/reports/pedagogicalareas"
              component={PedagogicalareasReport}
            />
            <PrivateRoute
              exact
              path="/reports/documentedactivities"
              component={DocumentedActivitiesReport}
            />
            <PrivateRoute exact path="/reports/analyse" component={Analyse} />
            <PrivateRoute
              exact
              path="/reports/reflections"
              component={Reflections}
            />
            <PrivateRoute exact path="/preschool" component={OurPreschool} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute
              exact
              path="/notifications"
              component={Notifications}
            />
            {/* <PrivateRoute exact path="/about" component={About} /> */}
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default AppRouter;
