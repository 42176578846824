import {
  SET_LANGUAGE,
  SET_SELECTED_PRESCHOOL,
  SET_SELECTED_DATE,
  SET_SITEVAR_EVENT_DATESPAN,
  SET_SITEVAR_EMPLOYEMENT_DATESPAN,
  SET_SITEVAR_REPORT_DATESPAN,
  RESET_STORE,
} from "../actions/types";

// import randomColor from "../../utils/randomColor";
// import section from "../../utils/getSectionById";
import moment from "moment";

const initialState = {
  language: "sv",
  selectedPreschool: null,
  eventsDateSpan: {
    dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
    dateTo: moment().endOf("month").format("YYYY-MM-DD"),
  },
  plannedActivitiesDateSpan: {
    dateFrom: moment().startOf("week").format("YYYY-MM-DD"),
    dateTo: moment().endOf("week").format("YYYY-MM-DD"),
  },
  statsDateSpan: {
    dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
    dateTo: moment().endOf("month").format("YYYY-MM-DD"),
  },
  employementsDateSpan: {
    dateFrom: moment().format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
  },
  reportDateSpan: {
    dateFrom: moment().startOf("month").add(-1, "month").format("YYYY-MM-DD"),
    dateTo: moment().endOf("month").add(-1, "month").format("YYYY-MM-DD"),
  },
  currentAcademicYear: null,
};

const colors = [
  "#F20C00",
  "#94FF4D",
  "#F780FF",
  // "#F20C00",
  "#1D3969",
  "#38384D",
  "#CF7D55",
  "#9FD6C4",
  "#DCFFA3",
  "#007CB5",
];

const getAcademicYear = (academicYears, dateSpan) => {
  const { dateFrom, dateTo } = dateSpan;
  console.log(academicYears, dateFrom, dateTo);
  const result = academicYears.find(
    (academicYear) =>
      moment(moment(academicYear.endDate).format("YYYY-MM-DD")).isSameOrAfter(
        dateTo
      ) &&
      moment(
        moment(academicYear.startDate).format("YYYY-MM-DD")
      ).isSameOrBefore(dateFrom)
  );
  console.log(result);
  return result;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_SELECTED_PRESCHOOL:
      const preschool = {
        ...action.payload,
        sections: action.payload.sections.map((section, index) => {
          return { ...section, color: colors[index] };
        }),
      };
      const academicYear =
        preschool.academicYear[preschool.academicYear.length - 1];
      return {
        ...initialState,
        selectedPreschool: preschool,
        currentAcademicYear: academicYear,
      };
    case SET_SELECTED_DATE:
      return {
        ...state,
        remindersDateSpan: action.payload,
      };
    case SET_SITEVAR_EVENT_DATESPAN:
      return {
        ...state,
        eventsDateSpan: action.payload,
      };
    case SET_SITEVAR_EMPLOYEMENT_DATESPAN:
      return {
        ...state,
        employementsDateSpan: action.payload,
      };
    case SET_SITEVAR_REPORT_DATESPAN:
      return {
        ...state,
        reportDateSpan: action.payload,
        currentAcademicYear: getAcademicYear(
          state.selectedPreschool.academicYear,
          action.payload
        ),
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
