import React from "react";
import AnaliseItemContainer from "./AnalyseItemContainer";
import moment from "moment";

export default function AnalyseListContainer({ analyses, section }) {
  const list =
    analyses.length > 0 &&
    analyses
      .filter((analyse) => analyse.sectionId === section._id)
      .sort(
        (a, b) =>
          moment(b.dateTo).format("YYYYMMDD") -
          moment(a.dateTo).format("YYYYMMDD")
      );
  return (
    <div style={{ marginBottom: 24 }}>
      <div style={{ fontSize: 24, fontWeight: "bold" }}>{section.name}</div>
      {list.length > 0 ? (
        list.map((item) => (
          <AnaliseItemContainer
            analyse={item}
            key={item._id + "analyseListItem"}
          />
        ))
      ) : (
        <div>Ingen utvärdering och analys har gjorts sedan läsårsstarten</div>
      )}
    </div>
  );
}
