import React, { useState, useEffect } from "react";
import moment from "moment";
import convertUriToBase64 from "../../../utils/urlToBase64";
import { useSelector } from "react-redux";

export default function ActivityItem({ activity, pedagogycalAreas, type }) {
  const { preschoolGoals } = useSelector((state) => state.preschoolData);
  const [backgroundImage, setBackgroundImage] = useState("");

  const milestones = activity.parentActivity.milestones;

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const findMilestone = (milestone) => {
    let result;
    pedagogycalAreas.forEach((pa) => {
      pa.centralcontent.forEach((cc) => {
        cc.milestones.forEach((ms) => {
          if (ms._id === milestone) {
            result = {
              milestoneShortName: ms.shortName,
              milestoneId: ms._id,
              pedagogicalAreaName: pa.name,
              pedagogicalAreaId: pa._id,
            };
          }
        });
      });
    });
    return result;
  };

  const milestonesDetail = milestones.map((milestone) =>
    findMilestone(milestone)
  );

  const pedagogicalAreasSorted = groupBy(milestonesDetail, "pedagogicalAreaId");

  const milestoneComponent = [];

  for (const paItem in pedagogicalAreasSorted) {
    const info = pedagogicalAreasSorted[paItem];

    milestoneComponent.push(
      <div key={paItem + "paItem"}>
        <div style={{ fontWeight: "bold" }}>{info[0].pedagogicalAreaName}</div>
        {info.map((i, index) => (
          <div style={{ fontSize: 12 }} key={index + i}>
            {i.milestoneShortName}
          </div>
        ))}
      </div>
    );
  }

  const goals =
    type === "documentation" && preschoolGoals.length > 0 ? (
      <div>
        {activity.documentation.achievedPreschoolGoals.map((goal) => {
          const filtered = preschoolGoals.filter((item) => item._id === goal);
          return (
            !!filtered.length && (
              <div key={goal + "goalINactivityItem" + activity._id}>
                {filtered[0].name}
              </div>
            )
          );
        })}
      </div>
    ) : (
      <div>Inget</div>
    );

  useEffect(() => {
    let activityImage =
      activity.images.length > 0 ? activity.images[0].path : null;
    activityImage =
      type === "documentation" && activity.documentation.images.length > 0
        ? activity.documentation.images[0].path
        : activityImage;

    if (activityImage) {
      convertUriToBase64(activityImage)
        .then((result) => setBackgroundImage(result))
        .catch((err) => console.log(err));
    } else {
      const milestoneId =
        type === "documentation"
          ? activity.documentation.achievedMilestones[0]
          : activity.parentActivity.milestones[0];
      pedagogycalAreas.map((pedagogicalArea) => {
        pedagogicalArea.centralcontent.map((centralContent) => {
          centralContent.milestones.map((milestone, index) => {
            if (milestone._id === milestoneId && backgroundImage === "") {
              setBackgroundImage(pedagogicalArea.header);
            }
          });
        });
      });
    }
  }, []);

  const description =
    type === "documentation"
      ? `${activity.documentation.comment}`
      : activity.comment;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginLeft: 10,
        marginRight: 10,
        minHeight: 170,
        marginTop: 5,
        marginBottom: 5,
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontSize: 20,
          fontWeight: "bold",
          marginRight: 16,
          display: "block",
          width: 30,
        }}
      >
        {type === "activity"
          ? moment(activity.plannedDate).format("ddd DD")
          : moment(activity.plannedDate).format("DD MMM YYYY")}
      </div>

      <div
        style={{
          flex: 1,
          color: "white",
          minHeight: 160,
          borderRadius: 10,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: 130,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 20,
            paddingBottom: 20,
            background: "rgba(0,0,0,0.6)",
            // background:
            //   "linear-gradient(180deg, rgba(0,0,0,0.520045518207283) 27%, rgba(0,0,0,0) 93%)",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              lineHeight: "18px",
              paddingBottom: 3,
            }}
          >
            {activity.name}
            <div
              style={{
                height: 1,
                backgroundColor: "rgba(248,247,252,1)",
                marginTop: 5,
                marginBottom: 30,
              }}
            />
          </div>

          <div
            style={{
              fontSize: 14,
              //display: "-webkit-box",
              // WebkitBoxOrient: "vertical",
              // WebkitLineClamp: 3,
              overflow: "hidden",
              // textOverflow: "ellipsis",
              // lineHeight: "16px",
            }}
          >
            {/* {description !== "" ? description : "Ingen observation"} */}
            {milestoneComponent}
            {type === "documentation" &&
              activity.documentation.achievedPreschoolGoals.length > 0 &&
              preschoolGoals.length > 0 && (
                <div>
                  <div style={{ fontWeight: "bold", marginTop: 8 }}>
                    Förskolans egna mål
                  </div>
                  <div>{goals}</div>
                </div>
              )}
            {type === "documentation" && activity.documentation.comment && (
              <div>
                <div style={{ fontWeight: "bold", marginTop: 5 }}>
                  Pedagogers observationer och reflektioner
                </div>
                <div style={{ fontStyle: "italic", whiteSpace: 'pre-wrap' }}>
                  {activity.documentation.comment}
                </div>
              </div>
            )}
            {type === "documentation" &&
              activity.documentation.childrensComment && (
                <div>
                  <div style={{ fontWeight: "bold", marginTop: 5 }}>
                    Barnens observationer och reflektioner
                  </div>
                  <div style={{ fontStyle: "italic" }}>
                    {activity.documentation.childrensComment}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
