import React, { useState, useEffect } from "react";
import moment from "moment";
import convertUriToBase64 from "../../../utils/urlToBase64";
import { useSelector } from "react-redux";

export default function ReflectionItem({ activity, pedagogycalAreas, type }) {
  const [backgroundImage, setBackgroundImage] = useState("");
  const { preschoolGoals } = useSelector((state) => state.preschoolData);

  const milestones = activity.parentActivity.milestones;

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const findMilestone = (milestone) => {
    let result;
    pedagogycalAreas.forEach((pa) => {
      pa.centralcontent.forEach((cc) => {
        cc.milestones.forEach((ms) => {
          if (ms._id === milestone) {
            result = {
              milestoneShortName: ms.shortName,
              milestoneName: ms.name,
              milestoneId: ms._id,
              pedagogicalAreaName: pa.name,
              pedagogicalAreaId: pa._id,
            };
          }
        });
      });
    });
    return result;
  };

  const milestonesDetail = milestones.map((milestone) =>
    findMilestone(milestone)
  );

  const pedagogicalAreasSorted = groupBy(milestonesDetail, "pedagogicalAreaId");

  const milestoneComponent = [];

  for (const paItem in pedagogicalAreasSorted) {
    const info = pedagogicalAreasSorted[paItem];

    milestoneComponent.push(
      <div>
        <div style={{ fontWeight: "bold" }}>{info[0].pedagogicalAreaName}</div>
        {info.map((i, index) => (
          <div style={{ fontSize: 12 }} key={i.name + "reflectionItem" + index}>
            {i.milestoneName}
          </div>
        ))}
      </div>
    );
  }

  const goals =
    preschoolGoals.length > 0 ? (
      <div>
        {activity.documentation.achievedPreschoolGoals.map((goal) => {
          const filtered = preschoolGoals.filter((item) => item._id === goal);
          return (
            !!filtered.lenght && (
              <div key={goal + "goalINactivityItem" + activity._id}>
                <div style={{ fontWeight: "bold" }}>{filtered[0].name}</div>
                <div style={{ fontSize: 12 }}>{filtered[0].description}</div>
              </div>
            )
          );
        })}
      </div>
    ) : null;

  useEffect(() => {
    let activityImage =
      activity.images.length > 0 ? activity.images[0].path : null;
    activityImage =
      activity.documentation.images.length > 0
        ? activity.documentation.images[0].path
        : activityImage;

    if (activityImage) {
      convertUriToBase64(activityImage)
        .then((result) => setBackgroundImage(result))
        .catch((err) => console.log(err));
    } else {
      const milestoneId = activity.parentActivity.milestones[0];
      pedagogycalAreas.map((pedagogicalArea) => {
        pedagogicalArea.centralcontent.map((centralContent) => {
          centralContent.milestones.map((milestone, index) => {
            if (milestone._id === milestoneId && backgroundImage === "") {
              setBackgroundImage(pedagogicalArea.header);
            }
          });
        });
      });
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginLeft: 10,
        marginRight: 10,
        minHeight: 170,
        marginTop: 5,
        marginBottom: 5,
        //alignItems: "center",
      }}
    >
      <div
        style={{
          fontSize: 20,
          fontWeight: "bold",
          marginRight: 16,
          display: "block",
          width: 30,
          paddingTop: 40,
        }}
      >
        {moment(activity.plannedDate).format("DD MMM YYYY")}
      </div>

      <div
        style={{
          flex: 1,
          //color: "white",
          minHeight: 160,
          borderRadius: 10,
          //backgroundImage: `url(${backgroundImage})`,
          backgroundColor: "#FCF8DB",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: 130,
            paddingBottom: 20,
            // background: "rgb(0,0,0)",
            // background:
            //   "linear-gradient(180deg, rgba(0,0,0,0.520045518207283) 27%, rgba(0,0,0,0) 93%)",
            //justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              padding: 10,
              backgroundColor: "#FCB817",
            }}
          >
            {activity.name}
          </div>

          <div
            style={{
              fontSize: 14,
              //display: "-webkit-box",
              // WebkitBoxOrient: "vertical",
              // WebkitLineClamp: 3,
              padding: 10,
              overflow: "hidden",
              // textOverflow: "ellipsis",
              // lineHeight: "16px",
            }}
          >
            {/* {description !== "" ? description : "Ingen observation"} */}
            {/* {milestoneComponent} */}
            <div style={{ whiteSpace: 'pre-wrap', fontSize: 16 }}>
              Reflektion: {activity.documentation.comment}
            </div>
            <div>
              <div
                style={{
                  height: 1,
                  backgroundColor: "rgba(99, 100, 102, 1)",
                  marginTop: 15,
                  marginBottom: 15,
                }}
              />
              <div>{milestoneComponent}</div>
            </div>
            <div style={{ marginTop: 10 }}>{goals}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
