import React, { useState, useEffect } from "react";

import RegularContainer from "../../container/RegularContainer";
import HorizontalBarChart from "../horizontalBarChart/HorizontalBarChart";

const SectionResultComponent = ({ section, stats, pedagogicalAreas }) => {
  const TitleComponent = ({ section }) => {
    return <div>{section.name}</div>;
  };

  //const [isLoading, setIsloading] = useState(section && stats ? false : true);
  const [sectionData, setSectionData] = useState([]);

  useEffect(() => {
    if (stats.documentedActivities && section && pedagogicalAreas) {
      let data = [];

      let documentedMilestones = [];
      stats.documentedActivities
        .filter((activity) => activity.section === section._id)
        .map((activity) => {
          documentedMilestones = documentedMilestones.concat(
            activity.documentation.achievedMilestones
          );
        });

      pedagogicalAreas &&
        pedagogicalAreas
          .filter((item) => item.active)
          .map((pedagogicalArea) => {
            const barColor = pedagogicalArea.color; // hämta pedagogicalArea och sedan färg till bar chart
            let count = 0;
            pedagogicalArea.centralcontent.map((centralContent) => {
              centralContent.milestones.map((milestone) => {
                if (
                  documentedMilestones.filter((item) => item === milestone._id)
                    .length > 0
                ) {
                  count += documentedMilestones.filter(
                    (item) => item === milestone._id
                  ).length;
                }
              });
            });
            data.push({
              _id: pedagogicalArea._id,
              name: pedagogicalArea.name,
              value: count,
              color: pedagogicalArea.color,
            });
          });

      let dataPlannedActivities = [];

      stats &&
        stats.documentedActivities.map((preschoolStat) => {
          let achieveCounter = 0;
          preschoolStat.documentation.achievedMilestones.map(
            (documentation) => {}
          );
          dataPlannedActivities.push({
            id: preschoolStat.id,
            name: preschoolStat.name,
            value: achieveCounter,
            barColor: preschoolStat.color,
          });
        });
      setSectionData(data);
    }
  }, [stats, section, pedagogicalAreas]);

  return (
    <RegularContainer
      titleComponent={() => <TitleComponent section={section} />}
    >
      <HorizontalBarChart data={sectionData} />
    </RegularContainer>
  );
};
const SectionResult = React.memo(SectionResultComponent);
export default SectionResult;
