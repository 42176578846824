import React from "react";
import HomeHeader from "../../../../assets/HomeHeader";

export default function HomeButton({ onClick, width, height }) {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#FCB817",
      }}
    >
      <HomeHeader width={width} height={height} />
      <div
        style={{
          height: 1,
          backgroundColor: "#FCB817",
          width: width + 20,
          margin: 2,
        }}
      />
      <div style={{ fontSize: 14 }}>Hem</div>
    </div>
  );
}
