import React, { useLayoutEffect } from "react";
import Select, { Heading } from "react-dropdown-select";
import styled from "@emotion/styled";

export default function MultiSelect({
  options,
  values,
  labelField,
  valueField,
  placeholder,
  addPlaceholder,
  onChange,
  title,
  error,
  disabled,
  toggleChooseAll,
}) {
  const customContentRenderer = ({ props, state }) => {
    const text =
      state.values.length === 0
        ? props.placeholder
        : `${state.values.length}  förskol${
            state.values.length > 1 ? "or" : "a"
          } vald${state.values.length > 1 ? "a" : ""} av ${
            props.options.length
          }`;
    return <div style={{ cursor: "pointer" }}>{text}</div>;
  };

  const customDropdownRenderer = ({ props, state, methods }) => {
    return (
      <div>
        <Items>
          {options.length > 1 && (
            <Item key={"allPreschools"} onClick={() => toggleChooseAll()}>
              {/* <input
                type="checkbox"
                onChange={() => toggleChooseAll()}
                checked={state.values.length === props.options.length}
              /> */}
              <ItemLabel style={{ textDecoration: "underline" }}>
                {state.values.length === props.options.length
                  ? "Avmarkera alla"
                  : "Markera alla"}
              </ItemLabel>
            </Item>
          )}

          {props.options.map((option) => {
            return (
              <Item
                key={option[props.valueField]}
                onClick={option.disabled ? null : () => methods.addItem(option)}
              >
                <input
                  type="checkbox"
                  onChange={() => methods.addItem(option)}
                  checked={
                    state.values.filter((item) => item._id === option._id)
                      .length > 0
                  }
                />
                <ItemLabel>{option[props.labelField]}</ItemLabel>
              </Item>
            );
          })}
        </Items>
      </div>
    );
  };

  return (
    <div>
      {title && <div style={{ marginBottom: 3 }}>{title}</div>}
      <Select
        options={options.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })}
        onChange={onChange}
        values={values}
        labelField={labelField}
        valueField={valueField}
        placeholder={placeholder}
        contentRenderer={customContentRenderer}
        dropdownRenderer={customDropdownRenderer}
        disabled={disabled}
        style={{
          borderRadius: 6,
          borderWidth: 0,
          padding: 8,
          backgroundColor: "#FCF8DB",
          marginTop: 5,
          marginBottom: 10,
          borderRadius: 6,
          // display: "flex",
          // flexDirection: "column",
        }}
        // color="#FCF8DB"
        addPlaceholder={addPlaceholder}
        multi
      />
      {error && (
        <div
          style={{
            marginTop: 3,
            fontSize: 12,
            fontStyle: "italic",
            color: "red",
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
}

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledSelect = styled(Select)`
  .react-dropdown-select-dropdown {
    overflow: initial;
  }
`;

const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin: 10px 10px 0;
    line-height: 30px;
    padding: 0px 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
      border: 1px solid deepskyblue;
    }
  }
`;

const Items = styled.div`
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
`;

const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
  ${({ disabled }) => disabled && "text-decoration: line-through;"}
`;

const ItemLabel = styled.div`
  margin: 5px 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  & div {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
`;

const Button = styled.button`
  background: none;
  border: 1px solid #555;
  color: #555;
  border-radius: 3px;
  margin: 10px 10px 0;
  padding: 3px 5px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  &.clear {
    color: tomato;
    border: 1px solid tomato;
  }
  :hover {
    border: 1px solid deepskyblue;
    color: deepskyblue;
  }
`;
