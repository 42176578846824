import {
  SET_PRESCHOOL_WEEKLY_PLANNING,
  SET_PRESCHOOL_EVENTS,
  SET_PRESCHOOL_GOALS,
  SET_PRESCHOOL_EMPLOYEES,
  RESET_STORE,
} from "../actions/types";

const initialState = {
  plannedActivities: [],
  events: [],
  preschoolGoals: [],
  employees: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PRESCHOOL_WEEKLY_PLANNING:
      return {
        ...state,
        plannedActivities: action.payload,
      };
    case SET_PRESCHOOL_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case SET_PRESCHOOL_GOALS:
      return {
        ...state,
        preschoolGoals: action.payload,
      };
    case SET_PRESCHOOL_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
