import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  SET_CURRENT_USER,
  SET_USER_AUTHORISATION,
  SET_SELECTED_PRESCHOOL,
  SET_ERRORS,
  RESET_STORE,
} from "./types";

export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/users/login", userData)
    .then((res) => {
      //Save to localstorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("authType", "password");
      //Set token to auth header
      setAuthToken(token);
      //Decode token to get user data
      const decoded = jwt_decode(token);
      //Set current user
      dispatch(setCurrentUser(decoded, "password"));
    })
    .catch((err) =>
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const loginFaceBookUser = (token) => (dispatch) => {
  localStorage.setItem("jwtToken", token);
  localStorage.setItem("authType", "azure");
  setAuthToken(token);
  //Decode token to get user data
  const decoded = jwt_decode(token);
  dispatch(setCurrentUser(decoded, "azure"));
  return decoded;
};

//Set loged in user
export const setCurrentUser = (decoded, type) => {
  return {
    type: SET_CURRENT_USER,
    payload: { decoded: decoded, type: type },
  };
};

//Log user out
export const logoutUser = () => async (dispatch) => {
  //Remove token from local storage
  await localStorage.removeItem("jwtToken");
  await localStorage.removeItem("authType");
  //Remove auth header
  setAuthToken(false);
  //Set current user to empty
  //dispatch(setCurrentUser({}, ""));
  dispatch({ type: RESET_STORE, payload: null });
};

export const getUserAuthorizations = () => (dispatch) => {
  axios
    .get("/v2/user/authList")
    .then((res) => {
      dispatch({
        type: SET_USER_AUTHORISATION,
        payload: res.data,
      });

      let selectedPreschool;
      const localStorageSelectedPreschool = JSON.parse(
        localStorage.getItem("selectedPreschool")
      );
      if (localStorageSelectedPreschool) {
        res.data.map((item) => {
          item.preschools.map((preschool) => {
            if (preschool._id === localStorageSelectedPreschool._id) {
              selectedPreschool = preschool;
            }
          });
        });
      }

      if (!selectedPreschool) {
        selectedPreschool = res.data[0].preschools[0];
        localStorage.setItem(
          "selectedPreschool",
          JSON.stringify(selectedPreschool)
        );
      }

      dispatch({
        type: SET_SELECTED_PRESCHOOL,
        payload: selectedPreschool,
      });
    })
    .catch((err) => {
      console.log(err.response);
    });
};
