import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import Header from "../../../newLayout/header/header";
import PedagogicalAreasRadar from "../../../newLayout/reports/pedagogicalAreasRadar/PedagogicalAreasRadar";
import Printerbutton from "../../../newLayout/common/buttons/printerButton/PrinterButton";

export default function PedagogicalAreasRepport() {
  const [maxValue, setMaxValue] = useState(15);

  const preschoolStats = useSelector((state) => state.preschoolStats);

  const pedagogycalAreas = useSelector((state) => state.pedagogy.pedagogy);

  const { selectedPreschool, reportDateSpan } = useSelector(
    (state) => state.siteVar
  );

  const history = useHistory();
  const navigate = (url) => {
    history.push(url);
  };

  useEffect(() => {
    let milestones = [];
    preschoolStats.documentedActivities.forEach(
      (doc) =>
        (milestones = milestones.concat(doc.documentation.achievedMilestones))
    );

    let count = Array.from(new Set(milestones)).map(
      (val) => milestones.filter((v) => v === val).length
    );
    count.sort((a, b) => b - a);

    setMaxValue(count[0]);
  }, [preschoolStats]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        maxWidth: 900,
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: 16,
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <Header title={selectedPreschool ? selectedPreschool.name : "..."} />
          {/* 5/7 Förskolor planerat aktiviteter för idag */}
        </div>
      </div>
      <div style={{ display: "flex", flex: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h1>
            Baserat på dokumenterade aktiviteter sedan läsårsstarten fram t.o.m.{" "}
            {moment(reportDateSpan.dateTo).format("MMMM YYYY")}
          </h1>

          {pedagogycalAreas &&
            pedagogycalAreas.map((pedagogycalArea, index) => {
              return (
                pedagogycalArea.active && (
                  <PedagogicalAreasRadar
                    pedagogicalArea={pedagogycalArea}
                    stats={preschoolStats.documentedActivities}
                    key={`${index}${pedagogycalArea._id}radarChart`}
                    preschool={selectedPreschool}
                    maxValue={maxValue}
                  />
                )
              );
            })}
          <Printerbutton onClick={() => window.print()} />
        </div>
      </div>
    </div>
  );
}
