import React from "react";
import axios from "axios";
//import './App.css';
import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./store/actions";
import AppRouter from "./routers/AppRouter";
import moment from "moment";
import "moment/locale/sv";
import * as msTeams from "@microsoft/teams-js";

moment().locale("sv");

// import GlobalFonts from '../src/fonts/fonts';

axios.defaults.headers.locale = "sv";

// axios.defaults.baseURL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:5000"
//     : "https://api.edchild.com/";
axios.defaults.baseURL = "https://api.edchild.com";

//Check for auth token
if (localStorage.jwtToken) {
  //Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  //decode token and get user info & expiration
  const decoded = jwt_decode(localStorage.jwtToken);
  const authType = localStorage.authType;
  store.dispatch(setCurrentUser(decoded, authType));

  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/";
  }
}

const App = () => {
  msTeams.initialize();
  return (
    <div>
      {/* <Wrapper> 
      <GlobalFonts />
    </Wrapper> */}
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </div>
  );
};

// render() {
//   return (
//       <Wrapper>
//           <GlobalFonts />
//   // ...
//       </Wrapper>
//   );
// }
export default App;
