import {
  SET_CURRENT_USER,
  SET_USER_AUTHORISATION,
  RESET_STORE,
} from "../actions/types";
import isEmpty from "../../validation/is-empty";

const initialState = {
  isAuthenticated: false,
  userAuthorization: [],
  user: {},
  authType: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload.decoded,
        authType: action.payload.type,
      };
    case SET_USER_AUTHORISATION:
      return {
        ...state,
        userAuthorization: action.payload,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
