import React from "react";
import SectionEmployees from "../sectionEmployees/SectionEmployees";
import CreateEmployeeAndEmployement from "../createEmployeeAndEmployement/CreateEmployeeAndEmployement";

export default function EmployeeList({
  selectedPreschool,
  employees,
  roles,
  employementsDateSpan,
}) {
  return (
    <div
      style={{
        backgroundColor: "snow",
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          fontSize: 18,
          fontWeight: "bold",
          paddingLeft: 15,
        }}
      >
        Medarbetare
      </div>

      {selectedPreschool && roles.length > 0 && (
        <CreateEmployeeAndEmployement
          selectedPreschool={selectedPreschool}
          roles={roles}
          employementsDateSpan={employementsDateSpan}
        />
      )}
      {selectedPreschool && employees && (
        <SectionEmployees
          section={selectedPreschool}
          employees={employees}
          key={selectedPreschool._id + "employeelist"}
        />
      )}

      {selectedPreschool &&
        employees &&
        selectedPreschool.sections.map((section) => {
          return (
            <SectionEmployees
              section={section}
              employees={employees}
              key={section._id + "employeelist"}
            />
          );
        })}
    </div>
  );
}
