import React from "react";

export default function RegularContainer(props) {
  const Title = props.titleComponent;
  const Caption = props.captionComponent;


  return (
    <div style={{ marginBottom: 25, ...props.style }}>
      <div style={{ fontSize: 20, marginBottom: 15 }}>
        <Title />
        {props.captionComponent && <Caption />}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#FCF8DB",
          padding: 18,
          borderRadius: 16,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
