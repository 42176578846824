import React, { useState } from "react";
import "./dropdown.scss";
import arrow from "../../../utils/images/arrow.png";

const Dropdown = ({ preschools, selectedPreschool, onClick }) => {
  const [isVisible, setIsVisible] = useState(false);

  let preschoolsList = [];

  preschools.map(
    (organisation) =>
      (preschoolsList = preschoolsList.concat(organisation.preschools))
  );

  return (
    <div>
      <div onClick={() => setIsVisible(!isVisible)} className="dropdownArrow">
        {isVisible ? (
          <i className="arrow up"></i>
        ) : (
          <i className="arrow down"></i>
        )}
        {isVisible ? (
          <img src={arrow} className="arrowUp" />
        ) : (
          <img src={arrow} />
        )}
      </div>
      <div
        style={{
          visibility: isVisible ? "visible" : "hidden",
          display: "box",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontSize: 14,
            backgroundColor: "#FCF8DB",
            borderRadius: 15,
            //boxShadow: '0px 0px 2px 800px, rgba(99, 100, 102, 0.20)',
            zIndex: 10,
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 0%)",
            margin: "auto",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          {isVisible &&
            preschoolsList
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              .map((preschool) => (
                <div
                  className="dropdownList"
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 8,
                    paddingBottom: 8,
                    backgroundColor:
                      preschool._id === selectedPreschool._id && "#FCB817",
                    color: preschool._id === selectedPreschool._id && "white",
                  }}
                  onClick={() => {
                    onClick(preschool);
                  }}
                  key={`dropdownOrg_${preschool._id}`}
                >
                  <div className="dropdownList">{preschool.name}</div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
