import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import Header from "../../../newLayout/header/header";
import ActivityListContainer from "../../../newLayout/plannedActivitiesList/ActivityListContainer";
import Printerbutton from "../../../newLayout/common/buttons/printerButton/PrinterButton";

export default function PlannedActivities() {
  const { documentedActivities } = useSelector((state) => state.preschoolStats);

  const pedagogycalAreas = useSelector((state) => state.pedagogy.pedagogy);

  const { selectedPreschool, reportDateSpan } = useSelector(
    (state) => state.siteVar
  );

  const history = useHistory();
  const navigate = (url) => {
    history.push(url);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        maxWidth: 600,
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: 16,
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <Header title={selectedPreschool ? selectedPreschool.name : "..."} />
          {/* 5/7 Förskolor planerat aktiviteter för idag */}
        </div>
      </div>
      <div style={{ display: "flex", flex: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h1>
            Reflektioner från{" "}
            {moment(reportDateSpan.dateFrom).format("MMMM YYYY")}
          </h1>
          {selectedPreschool &&
            selectedPreschool.sections.map((section, index) => {
              const activities =
                documentedActivities &&
                documentedActivities.filter((activity) => {
                  const isSection = activity.section === section._id;
                  const isDateBefore = moment(activity.plannedDate).isBefore(
                    reportDateSpan.dateTo
                  );

                  const beginingOfMonth = moment(reportDateSpan.dateTo).startOf(
                    "month"
                  );

                  const isDateAfter = moment(beginingOfMonth).isBefore(
                    activity.plannedDate
                  );

                  return isSection && isDateBefore && isDateAfter;
                });
              return (
                <ActivityListContainer
                  type="reflection"
                  key={`${section._id}DocumentationList`}
                  activities={activities}
                  title={section.name}
                  pedagogycalAreas={pedagogycalAreas}
                />
              );
            })}

          <Printerbutton onClick={() => window.print()} />
        </div>
      </div>
    </div>
  );
}
