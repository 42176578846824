import React from 'react'
import { useHistory } from "react-router-dom";

import Header from "../../newLayout/header/header";

export default function Profile() {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            maxWidth: 600,
        }}>
            <div
                style={{
                    textAlign: "center",
                    fontSize: 16,
                }}
            >
                <div style={{ marginBottom: 8 }}>
                    <Header title="Min profil" />
                    {/* 5/7 Förskolor planerat aktiviteter för idag */}
                </div>
            </div>
            <div style={{ display: "flex", flex: 1 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        marginLeft: 10,
                        marginRight: 10,
                    }}
                >
                    Kommer inom kort.
                </div>

            </div>

        </div>
    )
}
