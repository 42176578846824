import React from "react";

export default function Save({ size, color, style }) {
  const fillColor = color ? color : "#FCB717";
  return (
    <div style={style ? style : {}}>
      <svg
        width={size ? size : 18}
        height={size ? size : 18}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.565 7.935L28.065 0.434999C27.9248 0.295977 27.7586 0.185989 27.5759 0.111342C27.3931 0.0366956 27.1974 -0.00114202 27 -6.77769e-07H3C2.20435 -6.77769e-07 1.44129 0.31607 0.87868 0.878679C0.31607 1.44129 0 2.20435 0 3V33C0 33.7956 0.31607 34.5587 0.87868 35.1213C1.44129 35.6839 2.20435 36 3 36H33C33.7956 36 34.5587 35.6839 35.1213 35.1213C35.6839 34.5587 36 33.7956 36 33V9C36.0011 8.80259 35.9633 8.6069 35.8887 8.42414C35.814 8.24138 35.704 8.07516 35.565 7.935ZM12 3H24V9H12V3ZM24 33H12V21H24V33ZM27 33V21C27 20.2044 26.6839 19.4413 26.1213 18.8787C25.5587 18.3161 24.7956 18 24 18H12C11.2044 18 10.4413 18.3161 9.87868 18.8787C9.31607 19.4413 9 20.2044 9 21V33H3V3H9V9C9 9.79565 9.31607 10.5587 9.87868 11.1213C10.4413 11.6839 11.2044 12 12 12H24C24.7956 12 25.5587 11.6839 26.1213 11.1213C26.6839 10.5587 27 9.79565 27 9V3.615L33 9.615V33H27Z"
          fill={fillColor}
        />
      </svg>
    </div>
  );
}
