import React, { useState } from "react";

export default function Button({ text, onClick, style, disabled }) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        height: 60,
        backgroundColor:
          isMouseOver && !disabled
            ? "rgba(252, 183, 23, 0.8)"
            : "rgba(252, 183, 23, 1)",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        color: isMouseOver && !disabled ? "white" : "white",
        fontSize: 18,
        fontWeight: "bold",
        borderRadius: 10,
        boxShadow: "0 0 15px rgba(0, 0, 0, 0.4)",
        paddingLeft: 20,
        paddingRight: 20,
        ...style,
      }}
      onClick={!disabled ? onClick : () => null}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {text}
    </div>
  );
}
