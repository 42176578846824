const section = (item, selectedPreschool) => {
  let result;
  selectedPreschool.sections.map((mySection) => {
    if (mySection._id === item.section) {
      result = mySection;
    }
  });
  return result;
};
export default section;
