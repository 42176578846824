import React from 'react'

export default function Delete() {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.35 22.7C9.10519 22.7 6.91078 22.0343 5.04428 20.7872C3.17778 19.54 1.72303 17.7674 0.863972 15.6935C0.0049178 13.6195 -0.21985 11.3374 0.218092 9.13573C0.656034 6.93405 1.73702 4.91167 3.32434 3.32434C4.91167 1.73702 6.93405 0.656034 9.13573 0.218092C11.3374 -0.21985 13.6195 0.0049178 15.6935 0.863972C17.7674 1.72303 19.54 3.17778 20.7872 5.04428C22.0343 6.91078 22.7 9.10519 22.7 11.35C22.6971 14.3593 21.5004 17.2445 19.3725 19.3725C17.2445 21.5004 14.3593 22.6971 11.35 22.7ZM11.35 1.70001C9.44142 1.70001 7.57569 2.26597 5.98875 3.32632C4.40182 4.38668 3.16495 5.8938 2.43457 7.65711C1.70418 9.42042 1.51308 11.3607 1.88543 13.2326C2.25777 15.1045 3.17685 16.824 4.52642 18.1736C5.876 19.5232 7.59547 20.4422 9.46738 20.8146C11.3393 21.1869 13.2796 20.9958 15.0429 20.2654C16.8062 19.5351 18.3133 18.2982 19.3737 16.7113C20.434 15.1243 21 13.2586 21 11.35C20.9968 8.79164 19.9791 6.33897 18.1701 4.52993C16.361 2.72089 13.9084 1.70318 11.35 1.70001Z" fill="#EB6532" />
            <path d="M15.7299 12.2H6.97988C6.75445 12.2 6.53825 12.1104 6.37884 11.951C6.21944 11.7916 6.12988 11.5754 6.12988 11.35C6.12988 11.1246 6.21944 10.9084 6.37884 10.749C6.53825 10.5896 6.75445 10.5 6.97988 10.5H15.7299C15.9553 10.5 16.1715 10.5896 16.3309 10.749C16.4903 10.9084 16.5799 11.1246 16.5799 11.35C16.5799 11.5754 16.4903 11.7916 16.3309 11.951C16.1715 12.1104 15.9553 12.2 15.7299 12.2Z" fill="#EB6532" />
        </svg>

    )
}
