import React from "react";

export default function PrinterIcon({ width, height }) {
  return (
    <svg
      width={width ? width : 32}
      height={height ? height : 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5 24.875H27.3375C28.149 24.8726 28.9265 24.5491 29.5003 23.9753C30.0741 23.4015 30.3976 22.624 30.4 21.8125V9.5625C30.3976 8.75102 30.0741 7.97347 29.5003 7.39966C28.9265 6.82586 28.149 6.50242 27.3375 6.5H4.0625C3.25102 6.50242 2.47347 6.82586 1.89966 7.39966C1.32586 7.97347 1.00242 8.75102 1 9.5625V21.8125C1.00242 22.624 1.32586 23.4015 1.89966 23.9753C2.47347 24.5491 3.25102 24.8726 4.0625 24.875H5.9"
        stroke="#FCB817"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M23.6381 15.0751H7.76215C6.73379 15.0751 5.90015 15.9087 5.90015 16.9371V29.1381C5.90015 30.1664 6.73379 31.0001 7.76215 31.0001H23.6381C24.6665 31.0001 25.5001 30.1664 25.5001 29.1381V16.9371C25.5001 15.9087 24.6665 15.0751 23.6381 15.0751Z"
        stroke="#FCB817"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M25.5001 6.49998V4.66248C25.4977 3.851 25.1743 3.07344 24.6005 2.49964C24.0267 1.92583 23.2491 1.6024 22.4376 1.59998H8.96265C8.15117 1.6024 7.37362 1.92583 6.79981 2.49964C6.226 3.07344 5.90257 3.851 5.90015 4.66248V6.49998"
        stroke="#FCB817"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M27.1001 10.7876C27.1001 11.333 26.658 11.7751 26.1126 11.7751C25.5673 11.7751 25.1251 11.333 25.1251 10.7876C25.1251 10.2422 25.5673 9.80007 26.1126 9.80007C26.658 9.80007 27.1001 10.2422 27.1001 10.7876Z"
        fill="#FCB817"
        stroke="#FCB817"
        strokeWidth="1.7"
      />
    </svg>
  );
}
