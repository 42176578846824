export const SET_ERRORS = "SET_ERRORS";

//Auth action
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_AUTHORISATION = "SET_USER_AUTHORISATION";
export const RESET_STORE = "RESET_STORE";

//SiteVar actions
export const SET_LANGUAGE = "SET_LANGUAGE";

//Pedagogy actions
export const SET_PEDAGOGICAL_AREA = "SET_PEDAGOGICAL_AREAS";

//Preschool data actions
export const SET_PRESCHOOL_WEEKLY_PLANNING = "SET_PRESCHOOL_WEEKLY_PLANNING";
export const SET_SELECTED_PRESCHOOL = "SET_SELECTED_PRESCHOOL";
export const SET_PRESCHOOL_EVENTS = "SET_PRESCHOOL_EVENTS";
export const SET_PRESCHOOL_GOALS = "SET_PRESCHOOL_GOALS";
export const SET_PRESCHOOL_EMPLOYEES = "SET_PRESCHOOL_EMPLOYEES";

export const SET_REMINDER_CALENDAR_OF_ACTIVITIES =
  "SET_REMINDER_CALENDAR_OF_ACTIVITIES";

//Preschool stats
export const SET_PRESCHOOL_TRENDS = "SET_PRESCHOOL_TRENDS";
export const SET_PRESCHOOL_DCCUMENTED_ACTIVITIES =
  "SET_PRESCHOOL_DCCUMENTED_ACTIVITIES";
export const SET_PRESCHOOL_GOALS_SUMMARY = "SET_PRESCHOOL_GOALS_SUMMARY";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const SET_PRESCHOOL_ANALYSE = "SET_PRESCHOOL_ANALYSE";

export const SET_ADD_REMINDER = "SET_ADD_REMINDER";

export const SET_DELETE_REMINDER = "SET_DELETE_REMINDER";

//SITE VARS
export const SET_SITEVAR_EVENT_DATESPAN = "SET_SITEVAR_EVENT_DATESPAN";
export const SET_SITEVAR_EMPLOYEMENT_DATESPAN =
  "SET_SITEVAR_EMPLOYEMENT_DATESPAN";
export const SET_SITEVAR_REPORT_DATESPAN = "SET_SITEVAR_REPORT_DATESPAN";

//Roles
export const SET_ROLES = "SET_ROLES";
