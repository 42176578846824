import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import validator from "validator";
import Select from "react-dropdown-select";
import MultiSelect from "../common/inputs/multiSelect/MultiSelect";
import HtmlInput from "../common/inputs/htmlInput/HtmlInput";

import TextField from "../common/inputs/textField/TextField";
import TextArea from "../common/inputs/textArea/TextArea";
import Picker from "../common/inputs/picker/Picker";
import ButtonWithIcon from "../common/buttons/buttonWithIcon/ButtonWithIcon";
import { getPreschoolEvents } from "../../../store/actions/";

const CreateEvent = ({ reminder, onDoneEditing }) => {
  const dispatch = useDispatch();
  const [preschools, setPreschools] = useState([]);
  const [selectedTabIndex, setSelecteTabIndex] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedPreschools, setSelectedPreschools] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [reminderId, setReminderId] = useState(null);
  const [errors, setErrors] = useState({});

  const userAuth = useSelector((state) => state.auth);
  const { eventsDateSpan, selectedPreschool } = useSelector(
    (state) => state.siteVar
  );

  const toggleChooseAll = () => {
    if (selectedPreschools.length === preschools.length) {
      setSelectedPreschools([]);
    } else {
      setSelectedPreschools(preschools);
    }
  };

  useEffect(() => {
    setSelectedPreschools([selectedPreschool]);
  }, [selectedPreschool]);

  useEffect(() => {
    if (reminder) {
      setTitle(reminder.title);
      setDescription(reminder.text);
      setSelecteTabIndex(reminder.type === "calendar" ? 0 : 1);
      setStartDate(moment(reminder.dateFrom).format("YYYY-MM-DD"));
      setEndDate(moment(reminder.dateTo).format("YYYY-MM-DD"));
      setReminderId(reminder._id);
    } else {
      setTitle("");
      setDescription("");
      setSelecteTabIndex(selectedTabIndex);
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setReminderId(null);
    }
  }, [reminder]);

  const onCancel = () => {
    setTitle("");
    setDescription("");
    setSelecteTabIndex(selectedTabIndex);
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setReminderId(null);
  };

  const validation = (data) => {
    let err = {};
    if (!validator.isLength(data.title, { min: 3 })) {
      err.title = "Titeln måste innehålla minst 3 tecken";
    }

    if (data.preschools.length === 0) {
      err.preschools = "Välj minst en förskola";
    }

    if (!validator.isLength(data.text, { min: 3 })) {
      err.description = "Måste innehålla minst 3 tecken";
    }

    if (!validator.isDate(data.dateFrom)) {
      err.dateFrom =
        selectedTabIndex === 0
          ? "Datumet är ogiltigt"
          : "Från datum är ogiltig";
    }

    if (!validator.isDate(data.dateTo) && selectedTabIndex !== 0) {
      err.dateTo = "Till datum är ogiltig";
    }

    if (
      selectedTabIndex !== 0 &&
      validator.isBefore(data.dateTo, data.dateFrom)
    ) {
      err.dateTo = "Till datum är tidigare från datum";
      err.dateFrom = "Från datum är senare till datum";
    }

    setErrors(err);
    return err;
  };

  const onSubmit = async () => {
    let reload = false;
    if (
      validator.isAfter(startDate, eventsDateSpan.dateFrom) &&
      validator.isBefore(startDate, eventsDateSpan.dateTo)
    ) {
      reload = true;
    }

    if (
      selectedTabIndex === 1 &&
      validator.isAfter(endDate, eventsDateSpan.dateFrom) &&
      validator.isBefore(endDate, eventsDateSpan.dateTo)
    ) {
      reload = true;
    }

    const newEvents = new Promise((resolve, reject) => {
      const valid = validation({
        dateFrom: startDate,
        dateTo: selectedTabIndex === 0 ? null : endDate,
        text: description,
        title: title,
        preschools: selectedPreschools,
      });

      Object.keys(valid).length && reject(valid);

      const loopSelector = selectedPreschools;

      if (!reminderId) {
        let data = [];
        for (let i = 0; i < loopSelector.length; i++) {
          const reminder = {
            preschoolId: loopSelector[i]._id,
            type: selectedTabIndex === 0 ? "calendar" : "sticky",
            dateFrom: startDate,
            dateTo: selectedTabIndex === 0 ? null : endDate,
            text: description,
            title: title,
            //_id: reminderId,
            createdByName: userAuth.user.name,
          };
          data.push(reminder);
          axios
            .post("/v2/reminders/new", reminder)
            .then((res) => {
              if (i === loopSelector.length - 1) {
                resolve(data);
              }
            })
            .catch((err) => reject(err));
        }
      } else {
        const reminder = {
          //preschoolId: loopSelector[i]._id,
          type: selectedTabIndex === 0 ? "calendar" : "sticky",
          dateFrom: startDate,
          dateTo: selectedTabIndex === 0 ? null : endDate,
          text: description,
          title: title,
          _id: reminderId,
          createdByName: userAuth.user.name,
        };
        axios
          .put(`/v2/reminders/${selectedPreschool._id}/${reminderId}`, reminder)
          .then((res) => {
            onDoneEditing();
            resolve([reminder]);
          })
          .catch((err) => reject(err));
      }
    });
    newEvents
      .then((result) => {
        alert(`${result.length} sparade händelser`);
        reload &&
          dispatch(
            getPreschoolEvents(
              selectedPreschool._id,
              eventsDateSpan.dateFrom,
              eventsDateSpan.dateTo
            )
          );
        setDescription("");
        setTitle("");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (userAuth.userAuthorization) {
      let authToPreschools = [];
      userAuth.userAuthorization.map((org) => {
        authToPreschools = authToPreschools.concat(org.preschools);
      });
      setPreschools(authToPreschools);
    }
  }, [userAuth]);

  const styles = {
    tabHeaderSelected: {
      display: "flex",
      width: 135,
      height: 29,
      backgroundColor: "#FCE59F",
      justifyContent: "center",
      alignItems: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      fontSize: 16,
      fontWeight: "bold",
      cursor: "pointer",
    },
    tabHeaderNotSelected: {
      display: "flex",
      width: 135,
      height: 29,
      backgroundColor: "#FCF8DB",
      justifyContent: "center",
      alignItems: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      fontSize: 16,
      fontWeight: "bold",
      cursor: "pointer",
    },
  };
  return (
    <div>
      <div style={{ fontSize: 18, fontWeight: "bold", paddingBottom: 10 }}>
        Skapa en händelse eller kommunicera
      </div>
      <div style={{ fontSize: 12, paddingBottom: 10 }}>
        Här kan du skapa en händelse som du vill att arbetslagen ska komma ihåg
        vid sin veckoplanering, såsom en planeringsdag, specifika aktiviteter
        som du vill ska planeras in, etc.
        <br />
        Här kan du även kommunicera med arbetslagen, exempelvis om du vill ge
        feedback eller kommentarer på reflektioner och analyser.
        <br />
        Det du skriver nedan blir synligt under ”Planera” i appen, högst upp
        under fältet ”Kom ihåg – meddelanden från ledningsgruppen”. Texten blir
        synlig för alla förskolor som du väljer.
      </div>
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={
              selectedTabIndex === 0
                ? styles.tabHeaderSelected
                : styles.tabHeaderNotSelected
            }
            onClick={() => setSelecteTabIndex(0)}
          >
            För en dag
          </div>
          <div
            style={
              selectedTabIndex === 1
                ? styles.tabHeaderSelected
                : styles.tabHeaderNotSelected
            }
            onClick={() => setSelecteTabIndex(1)}
          >
            För en period
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#FCE59F",
            padding: 10,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <TextField
            value={title}
            title="Titel"
            placeHolder="Titel"
            onChange={(e) => setTitle(e.target.value)}
            error={errors.title}
          />
          <MultiSelect
            title="Välj förskola / förskolor"
            options={preschools}
            onChange={(values) => setSelectedPreschools(values)}
            values={selectedPreschools}
            labelField="name"
            valueField="_id"
            placeholder="Välj förskola / förskolor"
            disabled={reminderId}
            style={{
              borderRadius: 6,
              borderWidth: 0,
              padding: 8,
              // backgroundColor: "#FCF8DB",
              marginTop: 5,
              marginBottom: 10,
              borderRadius: 6,
              // display: "flex",
              // flexDirection: "column",
            }}
            allSelectedText="Alla förskolor valda"
            error={errors.preschools}
            // color="#FCF8DB"
            addPlaceholder="Lägg en till förskola"
            toggleChooseAll={toggleChooseAll}
          />
          <div style={{ marginBottom: 0 }} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% 50%",
              gap: 10,
              paddingLeft: 5,
              paddingRight: 5,
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {selectedTabIndex === 1 && <div>Period</div>}
            <div style={{ gridColumn: 1 }}>
              <TextField
                title={selectedTabIndex === 0 ? "Datum" : "Från"}
                value={startDate}
                placeHolder="åååå-mm-dd"
                onChange={(e) => setStartDate(e.target.value)}
                inputStyle={{ fontWeight: "normal", fontSize: 14 }}
                size={12}
                error={errors.dateFrom}
              />
            </div>
            <div style={{ gridColumn: 2 }}>
              {selectedTabIndex === 1 && (
                <TextField
                  title="Till"
                  value={endDate}
                  placeHolder="åååå-mm-dd"
                  onChange={(e) => setEndDate(e.target.value)}
                  inputStyle={{ fontWeight: "normal", fontSize: 14 }}
                  size={12}
                  error={errors.dateTo}
                />
              )}
            </div>
          </div>
          <HtmlInput
            title="Händelsebeskrivning / meddelande"
            value={description}
            onChange={(html) => setDescription(html)}
            placeHolder={`Beskrivning av händelsen eller meddelandet du vill kommunicera för denna ${
              selectedTabIndex === 1 ? "period" : "dag"
            }`}
            inputStyle={{ fontWeight: "normal", fontSize: 14 }}
            error={errors.description}
            html
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {reminderId && (
            <ButtonWithIcon
              text="Avbryt"
              icon={null}
              iconsStyle={{ marginLeft: 5 }}
              onClick={() => onCancel()}
              style={{ marginTop: 10, marginRight: 10 }}
            />
          )}
          <ButtonWithIcon
            text={reminderId ? "Spara ändringar" : "Lägg till"}
            icon={!reminderId && <div>+</div>}
            iconsStyle={{ marginLeft: 5 }}
            onClick={() => onSubmit()}
            style={{ marginTop: 10 }}
          />
        </div>
      </div>
    </div>
  );
};

const CreateEventComponent = React.memo(CreateEvent);
export default CreateEventComponent;
