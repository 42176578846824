import React, { useState } from "react";

export default function ButtonWithIcon({
  style,
  icon,
  iconsStyle,
  iconSide,
  text,
  onClick,
  disabled,
}) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const containerStyle = {
    display: "inline-block",
    borderRadius: 6,
    fontSize: 16,
    fontWeight: "bold",
    padding: 7,
    backgroundColor: isMouseOver && !disabled ? "#FCF8DB" : "#FCE59F",
    border: "solid",
    borderWidth: 0.1,
    borderColor: "#FCE59F",
    color: "#636466",
    fontFamily: "arialRounded",
    //boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
    cursor: disabled ? "wait" : "pointer",
    ...style,
  };

  return (
    <div
      style={containerStyle}
      onClick={!disabled ? onClick : () => null}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {iconSide === "left" && <div style={iconsStyle}>{icon} </div>}
        {text}
        {(iconSide === "right" || !iconSide) && (
          <div style={iconsStyle}> {icon}</div>
        )}
      </div>
    </div>
  );
}
