import React from "react";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css";
import "./style.css";

export default function HtmlInput({
  title,
  value,
  onChange,
  placeHolder,
  error,
  name,
  rows,
  style,
  inputStyle,
  size,
  caption,
}) {
  const containerStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    ...style,
  };
  const textInputStyle = {
    flex: 1,
    borderRadius: 6,
    borderWidth: 0,
    fontSize: 16,
    fontWeight: "bold",
    padding: 8,
    backgroundColor: "#FCF8DB",
    color: "#636466",
    fontFamily: "arialRounded",
    ...inputStyle,
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  return (
    <div style={containerStyle}>
      {title && <div style={{ marginBottom: 3 }}>{title}</div>}
      <ReactQuill
        value={value}
        onChange={onChange}
        theme="snow"
        modules={modules}
        formats={formats}
      />
      {caption && (
        <div
          style={{
            marginTop: 3,
            fontSize: 12,
            fontStyle: "italic",
          }}
        >
          {caption}
        </div>
      )}
      {error && (
        <div
          style={{
            marginTop: 3,
            fontSize: 12,
            fontStyle: "italic",
            color: "red",
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
