import React, { useState, useEffect } from "react";

import RegularContainer from "../../container/RegularContainer";
import HorizontalBarChart from "../horizontalBarChart/HorizontalBarChart";
import { Link } from "react-router-dom";

const PreschoolOwnGoalsComponent = ({ stats }) => {
  const TitleComponent = () => {
    return <div>Förskolans egna mål</div>;
  };

  const [isLoading, setIsloading] = useState(stats ? false : true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (stats) {
      const goals = stats.map((goal) => {
        return {
          name: goal.name,
          value: goal.count,
          color: "#ABBDC8",
        };
      });
      setData(goals);
    }
  }, [stats]);

  return (
    <RegularContainer titleComponent={() => <TitleComponent />}>
      {stats.length > 0 ? (
        <HorizontalBarChart data={data} gridVisible={true} />
      ) : (
        <div>
          Förskolan har inga mål registrerade. Du kan registrera dem under{" "}
          <Link to="/preschool">Vår förskola</Link>
        </div>
      )}
    </RegularContainer>
  );
};
const PreschoolOwnGoals = React.memo(PreschoolOwnGoalsComponent);
export default PreschoolOwnGoals;
