import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import validator from "validator";
import MultiSelect from "../common/inputs/multiSelect/MultiSelect";

import TextField from "../common/inputs/textField/TextField";
import TextArea from "../common/inputs/textArea/TextArea";
import ButtonWithIcon from "../common/buttons/buttonWithIcon/ButtonWithIcon";
import { getPreschoolGoals } from "../../../store/actions/";

export const CreateGoal = ({ goal, onDoneEditing }) => {
  const dispatch = useDispatch();
  const [preschools, setPreschools] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [purpose, setPurpose] = useState("");
  const [content, setContent] = useState("");
  const [how, setHow] = useState("");
  const [selectedPreschools, setSelectedPreschools] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [goalId, setGoalId] = useState(null);
  const [errors, setErrors] = useState({});

  const userAuth = useSelector((state) => state.auth);
  const { eventsDateSpan, selectedPreschool } = useSelector(
    (state) => state.siteVar
  );

  useEffect(() => {
    selectedPreschool && setSelectedPreschools([selectedPreschool]);
  }, [selectedPreschool]);

  const toggleChooseAll = () => {
    if (selectedPreschools.length === preschools.length) {
      setSelectedPreschools([]);
    } else {
      setSelectedPreschools(preschools);
    }
  };

  useEffect(() => {
    console.log(goal);
    if (goal) {
      setTitle(goal.name ? goal.name : "");
      setDescription(goal.description ? goal.description : "");
      setPurpose(goal.purpose ? goal.purpose : "");
      setContent(goal.content ? goal.content : "");
      setHow(goal.how ? goal.how : "");
      setSelectedPreschools([selectedPreschool]);
      setStartDate(moment(goal.startDate).format("YYYY-MM-DD"));
      setEndDate(moment(goal.endDate).format("YYYY-MM-DD"));
      setGoalId(goal._id);
    } else {
      setTitle("");
      setDescription("");
      setPurpose("");
      setContent("");
      setHow("");
      setSelectedPreschools([selectedPreschool]);
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setGoalId(null);
    }
  }, [goal]);

  const validation = (data) => {
    let err = {};
    if (!validator.isLength(data.name, { min: 3 })) {
      err.title = "Titeln måste innehålla minst 3 tecken";
    }

    if (!validator.isLength(data.description, { min: 3 })) {
      err.description = "Beskrivning måste innehålla minst 3 tecken";
    }

    if (!validator.isDate(data.startDate)) {
      err.dateFrom = "Från-datum är ogiltig";
    }

    if (!validator.isDate(data.endDate)) {
      err.dateTo = "Till-datum är ogiltig";
    }

    if (validator.isBefore(data.endDate, data.startDate)) {
      err.dateTo = "Till-datum är tidigare från-datum";
      err.dateFrom = "Från-datum är senare till-datum";
    }

    if (data.preschools.length === 0) {
      err.preschools = "Välj minst en förskola";
    }

    setErrors(err);
    return err;
  };

  const onSubmit = async () => {
    // let reload = false;
    // if (
    //   validator.isAfter(startDate, eventsDateSpan.dateFrom) &&
    //   validator.isBefore(startDate, eventsDateSpan.dateTo)
    // ) {
    //   reload = true;
    // }

    // if (
    //   validator.isAfter(endDate, eventsDateSpan.dateFrom) &&
    //   validator.isBefore(endDate, eventsDateSpan.dateTo)
    // ) {
    //   reload = true;
    // }

    const newEvents = new Promise((resolve, reject) => {
      const valid = validation({
        startDate: startDate,
        endDate: endDate,
        name: title,
        description: description,
        purpose: purpose,
        content: content,
        preschools: selectedPreschools,
        how: how,
      });

      Object.keys(valid).length && reject(valid);

      const loopSelector =
        selectedPreschools.length > 0 ? selectedPreschools : preschools;
      if (!goalId) {
        let data = [];
        for (let i = 0; i < loopSelector.length; i++) {
          const goal = {
            preschoolId: loopSelector[i]._id,
            startDate: startDate,
            endDate: endDate,
            name: title,
            description: description,
            purpose: purpose,
            content: content,
            how: how,
            //_id: goalId,
          };
          data.push(goal);
          axios
            .post("/v2/pedagogy/preschoolgoals/new", goal)
            .then((res) => {
              if (i === loopSelector.length - 1) {
                resolve(data);
              }
            })
            .catch((err) => reject(err));
        }
      } else {
        console.log("yeah");
        const goal = {
          //preschoolId: selectedPreschool._id,
          startDate: startDate,
          endDate: endDate,
          name: title,
          description: description,
          purpose: purpose,
          content: content,
          how: how,
          _id: goalId,
        };
        axios
          .put(
            `/v2/pedagogy/preschoolgoals/${selectedPreschool._id}/${goalId}`,
            goal
          )
          .then((res) => {
            console.log(res.data);
            resolve([goal]);
          })
          .catch((err) => reject(err));
      }
    });
    newEvents
      .then((result) => {
        alert(`Mål sparat!`);
        setContent("");
        setDescription("");
        setErrors([]);
        setGoalId(null);
        setHow("");
        setSelectedPreschools([]);
        setPurpose("");
        setTitle("");

        onDoneEditing();

        dispatch(
          getPreschoolGoals(
            selectedPreschool._id,
            moment().format("YYYY-MM-DD")
          )
        );
      })
      .catch((err) => console.log(err));
  };

  const onCancel = () => {
    setTitle("");
    setDescription("");
    setPurpose("");
    setContent("");
    setHow("");
    setSelectedPreschools([selectedPreschool]);
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setGoalId(null);
  };

  useEffect(() => {
    if (userAuth.userAuthorization) {
      let authToPreschools = [];
      userAuth.userAuthorization.map((org) => {
        authToPreschools = authToPreschools.concat(org.preschools);
      });
      setPreschools(authToPreschools);
    }
  }, [userAuth]);

  return (
    <div>
      <div style={{ fontSize: 18, fontWeight: "bold", paddingBottom: 10 }}>
        Skapa ett mål / projekt / tema
      </div>
      <div style={{ fontSize: 12, paddingBottom: 10 }}>
        Förskolan behöver ibland sätta upp egna mål som handlar om det egna
        arbetet för att på bästa sätt nå de nationella målen utifrån sina
        specifika förutsättningar och utmaningar. Dessa betecknar vi som
        förskolans mål, eller förskolans egna mål, och du kan lägga till dem
        nedan.
        <br />
        Här kan du även lägga till projekt/teman. Med ett temainriktat
        arbetssätt kan barnens lärande bli mångsidigt och sammanhängande.
        <br /> Det du skriver nedan blir synligt exempelvis synligt under ”Vår
        förskola” i appen samt där pedagogerna har möjlighet att dokumentera och
        analysera.
      </div>
      <div>
        <div
          style={{
            backgroundColor: "#FCE59F",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <TextField
            title="Titel"
            value={title}
            placeHolder="Titel"
            onChange={(e) => setTitle(e.target.value)}
            error={errors.title}
          />
          {/* <Picker
            items={preschools}
            selectedItems={selectedPreschools}
            itemsNamePlural="förskolor"
            itemsNameSingle="förskola"
            style={{
              marginTop: 10,
              marginBottom: 10,
              borderRadius: 6,
              backgroundColor: "#FCF8DB",
              backgroundColor: "#FCF8DB",
              display: "inline-block",
            }}
            onToggle={onToggle}
          /> */}
          <MultiSelect
            title="Välj förskola / förskolor"
            options={preschools}
            onChange={(values) => setSelectedPreschools(values)}
            values={selectedPreschools}
            labelField="name"
            valueField="_id"
            placeholder="Välj förskola / förskolor"
            style={{
              borderRadius: 6,
              borderWidth: 0,
              padding: 8,
              // backgroundColor: "#FCF8DB",
              marginTop: 5,
              marginBottom: 10,
              borderRadius: 6,
              // display: "flex",
              // flexDirection: "column",
              allSelectedText: "Alla förskolor valda",
            }}
            disabled={goalId}
            error={errors.preschools}
            // color="#FCF8DB"
            addPlaceholder="Lägg en till förskola"
            toggleChooseAll={toggleChooseAll}
          />
          <div style={{ marginBottom: 0 }} />
          Period
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% 50%",
              gap: 10,
              paddingLeft: 5,
              paddingRight: 5,
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div style={{ gridColumn: 1 }}>
              <TextField
                title="Från"
                value={startDate}
                placeHolder="åååå-mm-dd"
                onChange={(e) => setStartDate(e.target.value)}
                inputStyle={{ fontWeight: "normal", fontSize: 14 }}
                size={12}
                error={errors.dateFrom}
              />
            </div>

            <div style={{ gridColumn: 2 }}>
              <TextField
                title="Till"
                value={endDate}
                placeHolder="åååå-mm-dd"
                onChange={(e) => setEndDate(e.target.value)}
                inputStyle={{ fontWeight: "normal", fontSize: 14 }}
                size={12}
                error={errors.dateTo}
              />
            </div>
          </div>
          <TextArea
            title="Kort beskrivning (obligatorisk)"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeHolder="Kort övergripande beskrivning som exempelvis syns i dokumentationerna."
            inputStyle={{ fontWeight: "normal", fontSize: 14 }}
            error={errors.description}
            style={{ marginBottom: 5 }}
          />
          <TextArea
            title="Varför? - Syftet"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
            placeHolder="Exempelvis kort beskrivning av bakgrund och vad vi vill ge barnen erfarenhet av. Texten syns i appen under ”Vår förskola”."
            inputStyle={{ fontWeight: "normal", fontSize: 14 }}
            error={errors.purpose}
            style={{ marginBottom: 5 }}
          />
          <TextArea
            title="Vad? - Mål"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeHolder="Exempelvis mål från styrdokument och/eller mål i form av vad utvecklingsinsatsen förväntas leda till. Texten syns i appen under ”Vår förskola”."
            inputStyle={{ fontWeight: "normal", fontSize: 14 }}
            error={errors.content}
            style={{ marginBottom: 5 }}
          />
          <TextArea
            title="Hur? - Tillvägagångssätt"
            value={how}
            onChange={(e) => setHow(e.target.value)}
            placeHolder="Grovplanering; en planering är viktig för att kunna styra och följa arbetet och för att veta att vi gör rätt saker. Planeringen är kartan och leder oss i det arbete som ska göras. Texten syns i appen under ”Vår förskola”."
            inputStyle={{ fontWeight: "normal", fontSize: 14 }}
            error={errors.how}
            style={{ marginBottom: 5 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {goalId && (
            <ButtonWithIcon
              text="Avbryt"
              icon={null}
              iconsStyle={{ marginLeft: 5 }}
              onClick={() => onCancel()}
              style={{ marginTop: 10, marginRight: 10 }}
            />
          )}
          <ButtonWithIcon
            text={goalId ? "Spara ändringar" : "Lägg till"}
            icon={!goalId && <div>+</div>}
            iconsStyle={{ marginLeft: 5 }}
            onClick={() => onSubmit()}
            style={{ marginTop: 10 }}
          />
        </div>
      </div>
    </div>
  );
};

const CreateGoalComponent = React.memo(CreateGoal);
export default CreateGoalComponent;
