import React from "react";
import EdChild from "../../../assets/edChild";

export default function Footer() {
  return (
    <div style={{ paddingTop: 40, textAlign: "center" }}>
      <EdChild />
    </div>
  );
}
