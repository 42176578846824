import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import Header from "../../newLayout/header/header";
import QualityWorkStructure from "../../newLayout/reports/qualityWorkStructure/QualityWorkStructure";
import ChildrensRatings from "../../newLayout/reports/childrensRatings/ChildrensRatings";
import PreschoolOwnGoals from "../../newLayout/reports/preschoolOwnGoals/PreschoolOwnGoals";
import SectionResult from "../../newLayout/reports/sectionResult/SectionResult";
import ArrowMove from "../../newLayout/common/buttons/arrowMove/ArrowMove";
import { setReportDateSpan } from "../../../store/actions";

import Button from "../../newLayout/common/buttons/Button";
import Printerbutton from "../../newLayout/common/buttons/printerButton/PrinterButton";

export default function Reports() {
  const dispatch = useDispatch();
  const preschoolStats = useSelector((state) => state.preschoolStats);

  const pedagogycalAreas = useSelector((state) => state.pedagogy.pedagogy);

  const { selectedPreschool, reportDateSpan } = useSelector(
    (state) => state.siteVar
  );

  const history = useHistory();
  const navigate = (url) => {
    history.push(url);
  };

  const onChangeDate = (direction) => {
    let dateFrom;
    let dateTo;

    if (direction === "add") {
      dateFrom = moment(reportDateSpan.dateFrom)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      dateTo = moment(reportDateSpan.dateTo)
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      dateFrom = moment(reportDateSpan.dateFrom)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      dateTo = moment(reportDateSpan.dateTo)
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    dispatch(setReportDateSpan(dateFrom, dateTo));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        maxWidth: 600,
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: 16,
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <Header title={selectedPreschool ? selectedPreschool.name : "..."} />
          {/* 5/7 Förskolor planerat aktiviteter för idag */}
        </div>
      </div>
      <div style={{ display: "flex", flex: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          {/* <h1>{_.capitalize(moment().add(-1, "month").format("MMMM YYYY"))}</h1> */}
          <div
            style={{
              fontSize: 30,
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 25,
              marginTop: 25,
            }}
          >
            {selectedPreschool && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <ArrowMove
                  onClick={() => onChangeDate("substract")}
                  // disabled={
                  //   moment(selectedPreschool.academicYear.startDate)
                  //     .startOf("month")
                  //     .format("YYYYMMDD") ===
                  //   moment(reportDateSpan.dateFrom)
                  //     .startOf("month")
                  //     .format("YYYYMMDD")
                  // }
                />{" "}
                {_.capitalize(
                  moment(reportDateSpan.dateFrom).format("MMMM YYYY")
                )}
                <ArrowMove
                  direction="right"
                  onClick={() => onChangeDate("add")}
                  disabled={
                    moment(reportDateSpan.dateTo)
                      .endOf("month")
                      .format("YYYYMMDD") ===
                    moment()
                      .subtract(1, "month")
                      .endOf("month")
                      .format("YYYYMMDD")
                  }
                />
              </div>
            )}
          </div>
          <div>
            <QualityWorkStructure trends={preschoolStats.trends} />
            <ChildrensRatings trends={preschoolStats.trends} />
            <div style={{ marginBottom: 25 }}>
              <div
                style={{ fontSize: 22, fontWeight: "bold", marginBottom: 15 }}
              >
                Hur blev det?
              </div>
              <Button
                text={`Läs alla reflektioner från ${moment(
                  reportDateSpan.dateFrom
                ).format("MMMM YYYY")}`}
                onClick={() => navigate("/reports/reflections")}
              />
            </div>
            <div style={{ marginBottom: 8 }}>
              <div style={{ fontSize: 22, fontWeight: "bold" }}>Var är vi?</div>
              <div style={{ fontSize: 10 }}>
                Baserat på dokumenterade aktiviteter fram t.o.m. slutet av{" "}
                {moment(reportDateSpan.dateFrom).format("MMMM YYYY")}
              </div>
            </div>
            <PreschoolOwnGoals stats={preschoolStats.preschoolGoals} />
            {selectedPreschool &&
              selectedPreschool.sections.map((section, index) => (
                <SectionResult
                  section={section}
                  stats={preschoolStats}
                  pedagogicalAreas={pedagogycalAreas}
                  key={`sectionStats_${section._id}`}
                />
              ))}
          </div>

          <div>
            <Button
              text="Visa läroplansområden i detalj"
              onClick={() => navigate("/reports/pedagogicalareas")}
              style={{ marginBottom: 15 }}
            />
            <div style={{ marginBottom: 8 }}>
              <div
                style={{ fontSize: 22, fontWeight: "bold", marginBottom: 15 }}
              >
                Vart ska vi?
              </div>
              <Button
                text="Läs alla utvärderingar och analyser sedan läsårsstarten"
                onClick={() => navigate("/reports/analyse")}
                style={{ marginBottom: 56 }}
              />
            </div>
            <div style={{ marginBottom: 8 }}>
              <div
                style={{ fontSize: 22, fontWeight: "bold", marginBottom: 15 }}
              >
                Bilaga
              </div>
              <Button
                text="Visa dokumenterade aktiviteter sedan läsårsstarten"
                onClick={() => navigate("/reports/documentedactivities")}
                style={{ marginBottom: 56 }}
              />
            </div>
          </div>
          <Printerbutton onClick={() => window.print()} />
        </div>
      </div>
    </div>
  );
}
