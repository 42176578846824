import React, { useState, useEffect, useRef } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
} from "recharts";

export default function HorizontalBarChart({ data, gridVisible }) {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, [elementRef]); //empty dependency array so it only runs once at render

  return (
    <div ref={elementRef} style={{ width: "100%" }}>
      <ResponsiveContainer
        width={width > 0 ? width : 300}
        height={data.length < 3 ? (data.length + 1) * 50 : data.length * 50}
      >
        <ComposedChart
          layout="vertical"
          data={[...data, { name: "", value: null, color: "#ACC8AC" }]}
          margin={{
            top: 10,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis
            type="number"
            tick={{ fontSize: 14 }}
            domain={[
              0,
              Math.max.apply(
                Math,
                data.map((item) => item.value)
              ) + 10,
            ]}
          />
          <YAxis
            dataKey="name"
            type="category"
            tick={{ fontSize: 14 }}
            width={135}
          />

          {gridVisible && <CartesianGrid stroke="#ABBDC8" horizontal={false} />}
          <Bar
            dataKey="value"
            barSize={30}
            fill="#413ea0"
            label={{ position: "right", fill: "#413ea0" }}
          >
            {data.map((item, index) => {
              return <Cell key={`cell-${index}`} fill={item.color} />;
            })}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
