import React, { useState } from "react";

export default function Button({ iconComponent, title, caption, onPress }) {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        borderRadius: 6,
        boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
        borderColor: "rgba(217, 212, 212, 1)",
        backgroundColor: !isMouseOver ? "white" : "#FCF8DB",
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 8,
        paddingRight: 8,
      }}
      onClick={onPress}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div style={{ fontWeight: "bold" }}>{title}</div>
      <div>{iconComponent}</div>
      <div style={{ fontSize: 11, textAlign: "center" }}>{caption}</div>
    </div>
  );
}
