import React, { useState, useEffect } from "react";
import moment from "moment";
import More from "../../../assets/More";
import Pencil from "../../../assets/Pencil";
import ContextualMenu from "../contextualMenu/ContextualMenu";
import TextArea from "../common/inputs/textArea/TextArea";
import Button from "../common/buttons/buttonWithIcon/ButtonWithIcon";
import axios from "axios";
import Save from "../../../assets/Save";
import BeatLoader from "react-spinners/BeatLoader";
import { getPreschoolEvaluations } from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import Close from "../../../assets/Close";

export default function AnalyseItemContainer({ analyse }) {
  const dispatch = useDispatch();
  const { selectedPreschool, currentAcademicYear } = useSelector(
    (state) => state.siteVar
  );
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isCommentFieldOpen, setIsCommentFieldOpen] = useState(false);
  const [comment, setComment] = useState(
    analyse.commentFromPpal.length > 0 ? analyse.commentFromPpal[0].comment : ""
  );
  const [isFetching, setIsFetching] = useState(false);
  const closeContextualMenu = () => {
    setIsMenuVisible(false);
  };

  const contextualMenuItems = [
    {
      _id: analyse._id,
      icon: <Pencil />,
      text:
        analyse.commentFromPpal.length > 0
          ? "Redigera kommentar"
          : "Skriv kommentar",
      onClick: () => setIsCommentFieldOpen(true),
      content: analyse,
    },
  ];

  const onSaveComment = () => {
    setIsFetching(true);
    let data = {
      ...analyse,
      comment: comment,
    };
    delete data.commentFromPpal;
    axios
      .put("/v2/plannedactivities/weeklyevaluation", data)
      .then((result) => {
        dispatch(
          getPreschoolEvaluations(
            selectedPreschool._id,
            moment(currentAcademicYear.startDate).format("YYYY-MM-DD"),
            moment(currentAcademicYear.endDate).format("YYYY-MM-DD")
          )
        )
          .then((res) => {
            setIsFetching(false);
            setIsCommentFieldOpen(false);
          })
          .catch((err) => {
            setIsFetching(false);
            console.log(err);
          });
      })
      .catch((err) => {
        setIsFetching(false);
        console.log(err.response);
      });
  };

  const preschoolGoals =
    analyse.preschoolGoals &&
    analyse.preschoolGoals.map((item) => {
      return (
        <div style={{ marginBottom: 10 }} key={item._id + "analyseItem"}>
          <div style={{ fontWeight: "bold" }}>{item.name}</div>
          <div>{item.comment ? item.comment : "Inget har antecknats"}</div>
        </div>
      );
    });
  return (
    <div
      style={{
        borderRadius: 10,
        marginBottom: 10,
        position: "relative",
        backgroundColor: "#FCF8DB",
        overflow: "hidden",
      }}
    >
      {isMenuVisible && (
        <ContextualMenu
          items={contextualMenuItems}
          closeContextualMenu={closeContextualMenu}
        />
      )}
      <div
        style={{
          backgroundColor: "#FCB817",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
          color: "white",
        }}
      >
        Skapad {moment(analyse.dateTo).format("dddd DD MMMM YYYY")}
        <More items={[]} onClick={() => setIsMenuVisible(!isMenuVisible)} />
      </div>
      <div style={{ padding: 10 }}>
        <div style={{ fontWeight: "bold", fontSize: 18 }}>
          Analys kring undervisning utifrån läroplanen
        </div>
        {analyse.aboutNextWeek && <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{analyse.aboutNextWeek}</div>}
        {preschoolGoals && preschoolGoals.length > 0 && (
          <div>
            <div style={{ fontWeight: "bold", fontSize: 18, marginTop: 10 }}>
              Analys utifrån förskolans egna mål
            </div>
            {preschoolGoals}
          </div>
        )}
        {!isCommentFieldOpen && analyse.commentFromPpal.length > 0 && (
          <div>
            <div style={{ fontWeight: "bold", fontSize: 18 }}>
              Kommentar (
              {analyse.commentFromPpal[0].readDate
                ? `Läst den ${moment(analyse.commentFromPpal.readDate).format(
                  "ddd DD MMM YYYY"
                )}`
                : "oläst"}
              )
            </div>
            <div>{analyse.commentFromPpal[0].comment}</div>
          </div>
        )}
        {isCommentFieldOpen && (
          <div>
            {" "}
            <div style={{ fontWeight: "bold", fontSize: 18, marginBottom: 5 }}>
              Kommentar
            </div>
            <TextArea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              inputStyle={{ backgroundColor: "white" }}
              placeHolder="Skriv en kommentar"
            />
            <div style={{ height: 5 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                text="Avbryt"
                style={{ width: 100, marginRight: 5 }}
                onClick={() => setIsCommentFieldOpen(false)}
                icon={<Close size={18} />}
                disabled={isFetching}
              />
              <Button
                text={!isFetching ? "Spara" : "Sparar"}
                style={{ width: 100 }}
                onClick={() => onSaveComment()}
                icon={
                  isFetching ? (
                    <div style={{ marginLeft: 5 }}>
                      <BeatLoader size={8} />
                    </div>
                  ) : (
                    <Save color="#636466" style={{ marginLeft: 5 }} />
                  )
                }
                disabled={isFetching}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
