import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MenuItem({ text, link, linkType }) {
  const { selectedPreschool } = useSelector((state) => state.siteVar);
  let displayLink = "";
  switch (linkType) {
    case "external":
      displayLink = (
        <a
          href={link}
          target="_blank"
          style={{
            fontSize: "18px",
            color: "rgba(99, 100, 102, 1)",
            textDecoration: "none",
          }}
        >
          {text}
        </a>
      );
      break;
    case "mail":
      displayLink = (
        <a
          href={`mailto:${link}?subject=Supportärende från rektorsgränsnitt - ${selectedPreschool && selectedPreschool.name}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fontSize: "18px",
            color: "rgba(99, 100, 102, 1)",
            textDecoration: "none",
          }}
        >
          {text}
        </a>
      );
      break;
    default:
      displayLink = (
        <Link
          to={link}
          style={{
            fontSize: "18px",
            color: "rgba(99, 100, 102, 1)",
            textDecoration: "none",
          }}
        >
          {text}
        </Link>
      );
  }
  return (
    <div
      style={{
        marginTop: "12.5px",
        marginBottom: "12.5px",
        textAlign: "left",
        paddingLeft: 30,
      }}
    >
      {displayLink}
    </div>
  );
}
