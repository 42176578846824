
import React from 'react';
 
export function IconUpcomingWeek(){

   return (
     
      <svg width="45" height="45" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="27" cy="27" r="24.5" stroke="#FDB913" strokeWidth="5"/>
      </svg>
      
   )
 


};
 