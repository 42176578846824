import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../newLayout/header/header";
import PreschoolGoalList from "../../newLayout/preschoolGoalList/PreschoolGoalList";
import EmployeeList from "../../newLayout/employeeList/EmployeeList";

export default function OurPreschool() {
  const roles = useSelector((state) => state.roles);
  const { preschoolGoals, employees } = useSelector(
    (state) => state.preschoolData
  );

  const { selectedPreschool, employementsDateSpan } = useSelector(
    (state) => state.siteVar
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        maxWidth: 600,
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: 16,
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <Header title={selectedPreschool ? selectedPreschool.name : "..."} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <PreschoolGoalList preschoolGoals={preschoolGoals} />
        <EmployeeList
          selectedPreschool={selectedPreschool}
          employees={employees}
          roles={roles}
          employementsDateSpan={employementsDateSpan}
        />
      </div>
    </div>
  );
}
