import React from "react";

export default function CircleNumber({ number, icon, size }) {
  return (
    <div
      style={{
        display: "flex",
        width: size ? size : 45,
        height: size ? size : 45,
        margin: 8,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: size ? size / 2 + 12 : 45 / 2 + 12,
        border: "solid",
        borderColor: "#FDB913",
        borderWidth: 6,
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          textAlign: "center",
          color: "#636466",
          fontSize: size / 3,
        }}
      >
        {number}
      </div>
    </div>
  );
}
