import axios from "axios";
import {
  SET_PRESCHOOL_TRENDS,
  SET_PRESCHOOL_DCCUMENTED_ACTIVITIES,
  SET_PRESCHOOL_GOALS_SUMMARY,
  SET_PRESCHOOL_ANALYSE,
} from "./types";

export const getPreschoolTrends =
  (preschoolId, dateFrom, dateTo) => (dispatch) => {
    console.log(preschoolId, dateFrom, dateTo);
    axios
      .get(`/v2/stats/preschool/trends2/${preschoolId}/${dateFrom}/${dateTo}`)
      .then((res) => {
        dispatch({ type: SET_PRESCHOOL_TRENDS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };

export const getPreschoolDocumentedActivities =
  (preschoolId, sectionId, dateFrom, dateTo) => (dispatch) => {
    ///////
    axios
      .get(
        `v2/plannedactivities/list/${preschoolId}/${sectionId}/${dateFrom}/${dateTo}`
      )
      .then((res) =>
        dispatch({
          type: SET_PRESCHOOL_DCCUMENTED_ACTIVITIES,
          payload: res.data,
        })
      )
      .catch((err) => console.log(err.response.data));
  };

export const getPreschoolGoalsStats = (preschoolId) => (dispatch) => {
  axios
    .get(`/v2/stats/preschool/preschoolGoalsSummary/${preschoolId}`)
    .then((res) => {
      dispatch({ type: SET_PRESCHOOL_GOALS_SUMMARY, payload: res.data });
    })
    .catch((err) => console.log(err));
};

export const getPreschoolEvaluations =
  (preschoolId, dateFrom, dateTo) => (dispatch) => {
    console.log(preschoolId, dateFrom, dateTo);
    return new Promise((resolve, reject) => {
      axios
        .get(`/v2/stats/preschool/analyse/${preschoolId}/${dateFrom}/${dateTo}`)
        .then((res) => {
          dispatch({ type: SET_PRESCHOOL_ANALYSE, payload: res.data });
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  };
