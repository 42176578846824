import React, { useState, useEffect } from "react";

import RegularContainer from "../../container/RegularContainer";
import HorizontalBarChart from "../horizontalBarChart/HorizontalBarChart";

const TitleComponent = () => {
  return <div>Gör barnen delaktiga</div>;
};

const CaptionComponent = () => {
  return <div style={{ fontSize: 12 }}>Vad barnen tyckte om aktiviteterna under denna månad (baserat på dokumentationer från hela förskolan)</div>
}

const ChildrensRatingsComponent = ({ trends, showGrid }) => {
  const [isLoading, setIsloading] = useState(trends.now ? false : true);
  const [maxValue, setMaxValue] = useState(10);
  const [data, setData] = useState([
    {
      name: "Mycket bra",
      value: 0,
    },
    {
      name: "Bra",
      value: 0,
    },
    {
      name: "Mindre bra",
      value: 0,
    },
  ]);

  useEffect(() => {
    if (trends.now) {
      setIsloading(false);
      setData([
        {
          name: "Mycket bra",
          value: trends.now.childrensRating.threeStars,
          color: "#ACC8AC",
        },
        {
          name: "Bra",
          value: trends.now.childrensRating.twoStars,
          color: "#DBCE65",
        },
        {
          name: "Mindre bra",
          value: trends.now.childrensRating.oneStar,
          color: "#E2AD74",
        },
      ]);
    } else {
      setIsloading(true);
      setData([
        {
          name: "Mycket bra",
          value: 0,
        },
        {
          name: "Bra",
          value: 0,
        },
        {
          name: "Mindre bra",
          value: 0,
        },
      ]);
    }
  }, [trends]);

  return (
    <RegularContainer titleComponent={() => <TitleComponent />} captionComponent={() => <CaptionComponent />}>

      <HorizontalBarChart data={data} />
    </RegularContainer>
  );
};
const ChildrensRatings = React.memo(ChildrensRatingsComponent);
export default ChildrensRatings;
