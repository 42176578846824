import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";

import CircleWithNumber from "../circleWithNumber/CircleWithNumber";
import RegularContainer from "../../container/RegularContainer";

const TitleComponent = () => {
  return <div>Kvalitetsarbetets struktur</div>;
};

const CaptionComponent = () => {
  return (
    <div style={{ fontSize: 12 }}>Under denna månad för hela förskolan</div>
  );
};

const QualityWorkStructure = ({ trends }) => {
  const [isLoading, setIsloading] = useState(trends.now ? false : true);

  useEffect(() => {
    if (trends.now) {
      setIsloading(false);
    } else {
      setIsloading(true);
    }
  }, [trends]);

  return (
    <RegularContainer
      titleComponent={() => <TitleComponent />}
      captionComponent={() => <CaptionComponent />}
    >
      <CircleWithNumber
        title="Planera"
        number={!isLoading ? trends.now.numberOfPlannedActivities : "--"}
        caption="Planerade aktiviteter"
      />
      <CircleWithNumber
        title="Dokumentera"
        number={!isLoading ? trends.now.numberOfDocumentations : "--"}
        caption="Genomförda och dokumenterade aktiviteter (ink reflektioner)"
      />
      <CircleWithNumber
        title="Utvärdera"
        number={!isLoading ? trends.now.numberOfWeeklyEvaluations : "--"}
        caption="Utvärderingar & analyser"
      />
    </RegularContainer>
  );
};

export default QualityWorkStructure;
