import React from "react";

export function IconMonthlyReports({ size }) {
  return (
    <svg
      width={size ? size : 45}
      height={size ? size : 45}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.97153 37.6523C6.64579 37.6525 6.32697 37.5584 6.05354 37.3813C5.78011 37.2043 5.56377 36.9519 5.43063 36.6546C3.81956 33.0565 3.13451 29.1125 3.43791 25.1818C3.74131 21.2512 5.02351 17.459 7.16766 14.1508C9.31182 10.8425 12.2498 8.12345 15.7138 6.24131C19.1778 4.35917 23.0577 3.3738 27.0001 3.375C27.4476 3.375 27.8768 3.55279 28.1933 3.86926C28.5098 4.18573 28.6876 4.61495 28.6876 5.0625V27C28.6876 27.3259 28.5933 27.6448 28.4161 27.9182C28.2389 28.1916 27.9862 28.4079 27.6888 28.5409L7.66024 37.5005C7.444 37.5993 7.20926 37.651 6.97153 37.6523Z"
        fill="#FDB913"
      />
      <path
        d="M33.0739 7.19066C32.9516 7.16585 32.8252 7.1685 32.704 7.19841C32.5828 7.22833 32.4698 7.28477 32.373 7.36367C32.2763 7.44257 32.1982 7.54195 32.1445 7.65466C32.0908 7.76736 32.0628 7.89058 32.0625 8.01543V26.9998C32.0616 27.9769 31.7785 28.9329 31.2471 29.7528C30.7157 30.5727 29.9587 31.2216 29.0672 31.6214L10.877 39.7615C10.7642 39.8119 10.6639 39.8863 10.5831 39.9797C10.5022 40.073 10.4429 40.1829 10.4092 40.3017C10.3755 40.4205 10.3683 40.5453 10.3881 40.6671C10.4078 40.789 10.4541 40.9051 10.5236 41.0071C11.9157 43.0545 13.6437 44.852 15.6347 46.3238C19.4067 49.1299 23.9862 50.6388 28.6875 50.6248C40.7837 50.6248 50.625 40.7835 50.625 28.6873C50.625 18.093 43.0755 9.22832 33.0739 7.19066Z"
        fill="#636466"
      />
    </svg>
  );
}
