import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
// import Header from '../components/layout/Header';
// import Navbar from '../components/layout/Navbar';
// import Footer from '../components/layout/Footer';
// import Loading from '../components/layout/Loading';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => !!state.auth.isAuthenticated);
  const loading = false;
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    fontFamily: "arialRounded",
  };

  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // const backgroundImage = {
  //     display: "flex",
  //     justifyContent: "center",
  //     backgroundImage: `url(${require("../assets/background.png")})`,
  //     backgroundPosition: "center",
  //     backgroundSize: "cover",
  //     backgroundRepeat: "no-repeat",
  //     width: "100vw",
  //     height: "100vh",
  //     color: "#636466",
  //     fontFamily: "arialRounded",
  //   };

  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? (
          loading ? (
            // <Loading />
            <div
              style={{
                ...containerStyle,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Loading...
            </div>
          ) : (
            <div style={containerStyle}>
              {/* <Header className="header" />
                                <Navbar /> */}
              <Component {...props} />
              {/* <Footer /> */}
            </div>
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
