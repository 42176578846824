import React from "react";

export default function HomeHeader({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75525 38.5004H33.2553C35.1855 38.5004 36.7553 36.9306 36.7553 35.0004V19.2504C36.7555 19.0205 36.7103 18.7929 36.6223 18.5806C36.5343 18.3682 36.4052 18.1754 36.2425 18.0131L22.2425 4.0131C22.0801 3.85045 21.8873 3.72141 21.675 3.63336C21.4627 3.54532 21.2351 3.5 21.0053 3.5C20.7754 3.5 20.5478 3.54532 20.3355 3.63336C20.1232 3.72141 19.9304 3.85045 19.768 4.0131L5.768 18.0131C5.60525 18.1754 5.47618 18.3682 5.38818 18.5806C5.30019 18.7929 5.25501 19.0205 5.25525 19.2504V35.0004C5.25525 36.9306 6.825 38.5004 8.75525 38.5004V38.5004ZM17.5053 35.0004V26.2504H24.5053V35.0004H17.5053ZM8.75525 19.9749L21.0053 7.72485L33.2553 19.9749L33.257 35.0004H28.0053V26.2504C28.0053 24.3201 26.4355 22.7504 24.5053 22.7504H17.5053C15.575 22.7504 14.0053 24.3201 14.0053 26.2504V35.0004H8.75525V19.9749Z"
        fill="#FCB817"
      />
    </svg>
  );
}
