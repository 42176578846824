import React from "react";
import ActivityItem from "./ActivityItem";
import RefectionItem from "./ReflectionItem";
import moment from "moment";

export default function ActivityListContainer({
  activities,
  title,
  pedagogycalAreas,
  type,
}) {
  let emptyContent;
  switch (type) {
    case "documentation":
      emptyContent = <div>Ingen aktivitet dokumenterades</div>;
      break;
    case "reflection":
      emptyContent = <div>Ingen reflektion har antecknats</div>;
      break;
    default:
      emptyContent = <div>Ingen aktivitet har planerats</div>;
  }

  const content =
    pedagogycalAreas &&
    activities
      .sort((a, b) =>
        type === "activity"
          ? moment(a.plannedDate).format("YYYYMMDD") -
            moment(b.plannedDate).format("YYYYMMDD")
          : moment(b.plannedDate).format("YYYYMMDD") -
            moment(a.plannedDate).format("YYYYMMDD")
      )
      .map((activity) => {
        return type !== "reflection" ? (
          <ActivityItem
            type={type}
            activity={activity}
            pedagogycalAreas={pedagogycalAreas}
            key={`${activity._id}DetailedRepportAvdelning`}
          />
        ) : (
          activity.documentation.comment !== "" && (
            <RefectionItem
              type={type}
              activity={activity}
              pedagogycalAreas={pedagogycalAreas}
              key={`${activity._id}DetailedRepportAvdelning`}
            />
          )
        );
      });

  return (
    <div>
      <div
        style={{
          marginLeft: 92,
          marginBottom: 10,
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        {title}
      </div>
      {activities.length > 0 ? content : emptyContent}
    </div>
  );
}
