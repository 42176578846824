import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import CarouselCard from "./CarouselCard";
import EmptyCard from "./EmptyCard";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import Loading from "./Loading";

SwiperCore.use([Navigation, Pagination]);

export default function Carousel({
  items,
  selectedPreschool,
  pedagogycalAreas,
}) {
  const sections =
    selectedPreschool &&
    selectedPreschool.sections.map((section) => {
      return {
        _id: section._id,
        name: section.name,
        numberOfActivities: items
          ? items.filter((item) => item.section === section._id).length
          : 0,
      };
    });

  const sectionsWithoutActivity =
    sections && sections.filter((section) => section.numberOfActivities === 0);

  const carouselItems = items
    ? items.concat(sectionsWithoutActivity)
    : sectionsWithoutActivity;

  return (
    <div
      style={{
        width: "100vw",
        maxWidth: 600,
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <div style={{ margin: 8 }}>
        Planerade aktiviteter idag på vår förskola:
      </div> */}
      <div
        style={{
          width: "100vw",
          maxWidth: 600,
          flex: 1,
          display: "flex",
        }}
      >
        {items && selectedPreschool && pedagogycalAreas ? (
          <Swiper
            style={{
              display: "flex",
              flex: 1,
              width: "100vw",
              maxWidth: 600,
            }}
            spaceBetween={5}
            slidesPerView={1}
            pagination={{ dynamicBullets: true }}
          >
            {selectedPreschool &&
              carouselItems.map((item, index) =>
                item.numberOfActivities !== 0 ? (
                  <SwiperSlide key={index} style={{ display: "flex" }}>
                    <CarouselCard
                      item={item}
                      selectedPreschool={selectedPreschool}
                      pedagogycalAreas={pedagogycalAreas}
                    />
                  </SwiperSlide>
                ) : (
                  <SwiperSlide key={index} style={{ display: "flex" }}>
                    <EmptyCard item={item} />
                  </SwiperSlide>
                )
              )}
          </Swiper>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}
