import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../newLayout/header/header";
import CreateEventComponent from "../../newLayout/createEventComponent/CreateEventComponent";
import moment from "moment";
import _ from "lodash";
import EventWithDateContainer from "../../newLayout/container/EventWithDateContainer";
import ArrowMove from "../../newLayout/common/buttons/arrowMove/ArrowMove";
import { setEventDateSpan, getPreschoolEvents } from "../../../store/actions";
import axios from "axios";

export default function Events() {
  const dispatch = useDispatch();
  const { selectedPreschool, eventsDateSpan } = useSelector(
    (state) => state.siteVar
  );
  const { events } = useSelector((state) => state.preschoolData);
  const [eventToEdit, setEventToEdit] = useState(null);

  const onChangeDate = (direction) => {
    let dateFrom;
    let dateTo;

    if (direction === "add") {
      dateFrom = moment(eventsDateSpan.dateFrom)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      dateTo = moment(eventsDateSpan.dateTo)
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      dateFrom = moment(eventsDateSpan.dateFrom)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      dateTo = moment(eventsDateSpan.dateTo)
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    dispatch(setEventDateSpan(dateFrom, dateTo));
  };

  const onDelete = (reminder) => {
    const confirmDelete = window.confirm(
      `Vill du radera ${reminder.title} för denna förskola?`
    );
    if (confirmDelete) {
      axios
        .delete(`/v2/reminders/${selectedPreschool._id}/${reminder._id}`)
        .then((res) => {
          alert(`${reminder.title} raderad`);
          dispatch(
            getPreschoolEvents(
              selectedPreschool._id,
              eventsDateSpan.dateFrom,
              eventsDateSpan.dateTo
            )
          );
        })
        .catch((err) =>
          alert(
            `${reminder.title} kunde inte raderas på grund av följande fel: ${err.response.data}}`
          )
        );
    } else {
      console.log("no");
    }
  };

  const onEdit = (reminder) => {
    setEventToEdit(reminder);
    window.scrollTo(0, 0);
    console.log(reminder);
  };

  const onDoneEditing = () => {
    setEventToEdit(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        maxWidth: 600,
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: 16,
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <Header title={selectedPreschool ? selectedPreschool.name : "..."} />
          {/* 5/7 Förskolor planerat aktiviteter för idag */}
        </div>
      </div>
      <div style={{ display: "flex", flex: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <CreateEventComponent
            reminder={eventToEdit}
            onDoneEditing={() => onDoneEditing()}
          />
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              fontSize: 16,
              fontWeight: "bold",
            }}
          >
            Kalendarium och kommunikation för
            <br />
            {selectedPreschool ? `${selectedPreschool.name}` : ""}
          </div>
          <div
            style={{
              fontSize: 30,
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 25,
              marginTop: 25,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <ArrowMove onClick={() => onChangeDate("substract")} />{" "}
              {_.capitalize(
                moment(eventsDateSpan.dateFrom).format("MMMM YYYY")
              )}
              <ArrowMove
                direction="right"
                onClick={() => onChangeDate("add")}
              />
            </div>
          </div>

          <div>
            {events.map((event) => (
              <EventWithDateContainer
                key={`${event._id}eventListItem`}
                event={event}
                style={{ marginBottom: 10 }}
                menuItems={[]}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
