import React from "react";

export default function edChild() {
  return (
    <svg
      width="260"
      height="58"
      viewBox="0 0 130 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.2433 0.420106C45.9174 0.402492 45.5921 0.463088 45.2944 0.596835C44.9967 0.730582 44.7353 0.9336 44.532 1.18894C44.1321 1.70357 43.9327 2.46103 43.9337 3.46135V10.6785C43.1332 9.76997 42.1741 9.01467 41.1033 8.44948C39.9883 7.93442 38.7688 7.68605 37.5412 7.72405C35.1076 7.72405 33.1442 8.57762 31.6509 10.2848C30.1577 11.9919 29.4116 14.3728 29.4126 17.4275C29.4015 18.7903 29.5916 20.1473 29.9769 21.4546C30.3174 22.63 30.8793 23.7296 31.6323 24.6942C32.3261 25.5855 33.2106 26.31 34.221 26.8147C35.2307 27.3139 36.3436 27.5688 37.4699 27.5588C38.2984 27.5662 39.1228 27.4427 39.9128 27.1929C40.6589 26.9493 41.3604 26.586 41.9899 26.1172C42.7022 25.5774 43.3539 24.962 43.9337 24.2819V24.7655C43.9337 25.6615 44.1517 26.3507 44.5878 26.8333C44.7939 27.0679 45.049 27.2545 45.335 27.3798C45.6211 27.5052 45.9311 27.5663 46.2433 27.5588C46.5547 27.5693 46.8646 27.5124 47.152 27.392C47.4393 27.2716 47.6972 27.0905 47.9081 26.8612C48.339 26.3962 48.554 25.6976 48.5529 24.7655V3.21334C48.5529 2.29569 48.3514 1.59816 47.9546 1.12694C47.741 0.88787 47.4761 0.700133 47.1798 0.577742C46.8835 0.45535 46.5634 0.401464 46.2433 0.420106ZM43.0285 21.0547C42.676 21.8933 42.0839 22.6093 41.3265 23.1132C40.623 23.5588 39.8061 23.7923 38.9735 23.7859C38.1382 23.7895 37.321 23.5434 36.6267 23.0791C35.8691 22.5576 35.2807 21.8259 34.934 20.9741C34.4949 19.9008 34.2848 18.7478 34.3171 17.5887C34.2938 16.4596 34.4918 15.3369 34.8999 14.284C35.2272 13.4149 35.7949 12.6569 36.5368 12.0983C37.2338 11.5931 38.0755 11.3266 38.9363 11.3388C39.7847 11.3356 40.6133 11.5955 41.3079 12.0828C42.0687 12.6257 42.6634 13.3696 43.0254 14.2312C43.4741 15.2923 43.6908 16.437 43.6609 17.5887C43.7019 18.7761 43.4862 19.9583 43.0285 21.0547Z"
        fill="#FCB817"
      />
      <path
        d="M68.0652 19.3807C67.5978 19.3683 67.1432 19.5346 66.7941 19.8458C66.4005 20.2173 66.0734 20.6535 65.8269 21.1354C65.3827 21.9653 64.7472 22.6773 63.973 23.2125C63.2199 23.6814 62.3456 23.9186 61.4588 23.8946C60.7674 23.9027 60.0826 23.7588 59.453 23.4729C58.8421 23.1921 58.3081 22.7678 57.8967 22.236C57.4439 21.6497 57.1091 20.9811 56.9109 20.2674C56.6767 19.4438 56.5618 18.5908 56.5699 17.7346C56.5699 15.7784 57.0111 14.2355 57.8936 13.1061C58.597 12.2421 59.5904 11.6632 60.6889 11.4773C61.7874 11.2913 62.916 11.511 63.8645 12.0954C64.611 12.652 65.2462 13.344 65.737 14.1353C65.9906 14.5124 66.2953 14.8525 66.6422 15.1459C66.934 15.3616 67.2903 15.472 67.6529 15.4591C68.2403 15.4725 68.8093 15.2543 69.2371 14.8514C69.4457 14.6649 69.6121 14.4359 69.7249 14.1798C69.8377 13.9236 69.8944 13.6463 69.8912 13.3665C69.8648 12.7077 69.6636 12.0679 69.3084 11.5126C68.8676 10.7899 68.2969 10.155 67.625 9.64009C66.817 9.01501 65.9094 8.53078 64.9403 8.20783C63.5077 7.7447 61.9978 7.56875 60.4971 7.69004C58.9964 7.81133 57.5344 8.22748 56.1947 8.91466C54.7399 9.74079 53.5669 10.985 52.828 12.486C52.0269 14.1419 51.6285 15.9637 51.6654 17.8028C51.6654 20.7634 52.5128 23.1237 54.2076 24.8835C55.9023 26.6433 58.2594 27.5238 61.279 27.5248C62.5789 27.5427 63.873 27.349 65.1108 26.9513C66.1374 26.6277 67.0943 26.1146 67.9319 25.4384C68.6105 24.894 69.176 24.2221 69.5967 23.4605C69.9339 22.8644 70.1211 22.1951 70.1423 21.5105C70.1325 20.9701 69.9214 20.4527 69.5502 20.0597C69.3697 19.842 69.1425 19.6679 68.8853 19.5504C68.6282 19.4328 68.3479 19.3748 68.0652 19.3807Z"
        fill="#FCB817"
      />
      <path
        d="M89.0439 10.7681C88.5283 9.79959 87.7405 9.00319 86.7777 8.47706C85.7606 7.93876 84.6234 7.6678 83.4729 7.68962C82.6921 7.68419 81.9145 7.78962 81.1633 8.00274C80.4619 8.20312 79.7995 8.52072 79.204 8.94208C78.5326 9.43475 77.9227 10.0062 77.3873 10.6441V3.39282C77.3873 2.41318 77.1703 1.67534 76.7332 1.17312C76.5058 0.91994 76.2246 0.720873 75.9103 0.590518C75.5959 0.460163 75.2564 0.401825 74.9165 0.419784C74.5826 0.401616 74.2488 0.459091 73.9401 0.587921C73.6315 0.716752 73.3559 0.913613 73.1339 1.16382C72.7092 1.65984 72.4974 2.40284 72.4984 3.39282V24.5916C72.4984 26.5726 73.3045 27.5636 74.9165 27.5646C75.2572 27.5827 75.5976 27.5235 75.9122 27.3914C76.2268 27.2593 76.5075 27.0578 76.7332 26.802C77.1672 26.2936 77.3873 25.5588 77.3873 24.5916V18.1278C77.3873 16.2646 77.5186 14.9698 77.7811 14.2433C78.0941 13.4276 78.6381 12.7209 79.3466 12.2096C80.0318 11.7175 80.8561 11.4569 81.6996 11.4656C83.0017 11.4656 83.8852 11.8862 84.3503 12.7274C84.8153 13.5685 85.0478 14.7714 85.0478 16.3359V24.5885C85.0478 26.5695 85.8538 27.5605 87.4659 27.5615C87.8002 27.5748 88.1332 27.5142 88.4414 27.384C88.7495 27.2538 89.0251 27.0572 89.2485 26.8082C89.6949 26.306 89.9212 25.5682 89.9212 24.5885V15.2261C89.9297 14.3897 89.8695 13.5539 89.7414 12.7274C89.6339 12.0363 89.3972 11.3716 89.0439 10.7681Z"
        fill="#FCB817"
      />
      <path
        d="M96.2581 7.68988C95.9313 7.67833 95.6059 7.73807 95.3044 7.86495C95.003 7.99183 94.7329 8.18281 94.5127 8.42462C94.0663 8.91444 93.84 9.64297 93.84 10.6102V24.595C93.84 25.5498 94.0642 26.2835 94.5127 26.7961C94.7273 27.0469 94.9957 27.2463 95.2978 27.3793C95.5999 27.5124 95.9281 27.5758 96.2581 27.5649C96.5904 27.5742 96.9209 27.5117 97.2269 27.3817C97.5329 27.2517 97.8073 27.0572 98.0314 26.8116C98.4964 26.3093 98.7289 25.5715 98.7289 24.5919V10.4645C98.7733 9.71932 98.5228 8.98658 98.0314 8.42462C97.8043 8.18402 97.5289 7.99418 97.2233 7.86755C96.9177 7.74092 96.5888 7.68038 96.2581 7.68988Z"
        fill="#FCB817"
      />
      <path
        d="M97.994 1.24157C97.5241 0.843201 96.9265 0.62748 96.3106 0.633942C95.6697 0.626829 95.0509 0.867894 94.5838 1.30667C94.342 1.52 94.15 1.78386 94.0215 2.07957C93.8929 2.37528 93.8309 2.69562 93.8398 3.01795C93.8206 3.34894 93.8758 3.68001 94.0014 3.98687C94.1269 4.29372 94.3196 4.56857 94.5652 4.79124C95.0492 5.20925 95.6712 5.43245 96.3106 5.41746C96.938 5.42914 97.5464 5.20196 98.0126 4.78193C98.2544 4.55872 98.4439 4.28488 98.5677 3.97996C98.6915 3.67504 98.7465 3.34656 98.7287 3.01795C98.7505 2.68536 98.6956 2.3522 98.5682 2.0442C98.4408 1.7362 98.2443 1.46159 97.994 1.24157Z"
        fill="#FCB817"
      />
      <path
        d="M105.351 0.420086C105.019 0.405583 104.688 0.465745 104.383 0.596132C104.078 0.726519 103.806 0.923802 103.587 1.17342C103.15 1.67564 102.933 2.41348 102.933 3.39312V24.5919C102.933 25.5468 103.157 26.2805 103.605 26.793C103.82 27.0439 104.088 27.2432 104.39 27.3763C104.693 27.5094 105.021 27.5728 105.351 27.5618C105.684 27.5729 106.015 27.5123 106.323 27.3839C106.631 27.2555 106.907 27.0624 107.133 26.8178C107.592 26.3218 107.821 25.5809 107.821 24.5888V3.39312C107.821 2.40108 107.595 1.66014 107.143 1.16412C106.915 0.917905 106.638 0.72388 106.328 0.595428C106.019 0.466976 105.685 0.407153 105.351 0.420086Z"
        fill="#FCB817"
      />
      <path
        d="M129.402 1.12668C129.188 0.887121 128.924 0.699051 128.627 0.576619C128.331 0.454188 128.011 0.400584 127.69 0.419843C127.365 0.402229 127.039 0.462825 126.741 0.596572C126.444 0.730319 126.182 0.933337 125.979 1.18868C125.579 1.7033 125.379 2.46077 125.378 3.46108V10.6782C124.578 9.76983 123.62 9.01452 122.55 8.44922C121.435 7.93416 120.216 7.68579 118.988 7.72378C116.555 7.72378 114.591 8.57735 113.098 10.2845C111.605 11.9916 110.859 14.3726 110.86 17.4272C110.849 18.79 111.039 20.147 111.424 21.4543C111.765 22.6297 112.326 23.7293 113.079 24.694C113.773 25.5852 114.658 26.3097 115.668 26.8145C116.678 27.3136 117.791 27.5685 118.917 27.5585C119.745 27.5659 120.57 27.4425 121.36 27.1927C122.106 26.9491 122.808 26.5857 123.437 26.1169C124.149 25.5771 124.801 24.9618 125.381 24.2816V24.7653C125.381 25.6612 125.599 26.3505 126.035 26.8331C126.241 27.0677 126.496 27.2542 126.782 27.3796C127.068 27.5049 127.378 27.566 127.69 27.5585C128.002 27.5687 128.312 27.5117 128.599 27.3913C128.886 27.2709 129.144 27.09 129.355 26.861C129.786 26.3959 130.001 25.6974 130 24.7653V3.21307C130.003 2.29233 129.802 1.5979 129.402 1.12668ZM124.479 21.0544C124.126 21.893 123.534 22.6091 122.777 23.1129C122.072 23.5589 121.254 23.7925 120.421 23.7856C119.585 23.7893 118.768 23.5431 118.074 23.0788C117.316 22.5574 116.728 21.8257 116.381 20.9738C115.942 19.9006 115.732 18.7475 115.764 17.5884C115.741 16.4594 115.939 15.3367 116.347 14.2837C116.674 13.4146 117.242 12.6566 117.984 12.0981C118.681 11.5928 119.523 11.3264 120.383 11.3385C121.232 11.3353 122.06 11.5953 122.755 12.0826C123.516 12.6254 124.11 13.3693 124.472 14.231C124.921 15.292 125.138 16.4368 125.108 17.5884C125.151 18.7757 124.937 19.9582 124.479 21.0544Z"
        fill="#FCB817"
      />
      <path
        d="M12.1107 16.708C12.1107 16.5026 12.0498 16.3018 11.9356 16.131C11.8215 15.9602 11.6593 15.8271 11.4696 15.7485C11.2798 15.6699 11.071 15.6493 10.8695 15.6894C10.668 15.7295 10.483 15.8284 10.3378 15.9736C10.1925 16.1189 10.0936 16.3039 10.0535 16.5054C10.0135 16.7068 10.034 16.9156 10.1126 17.1054C10.1912 17.2952 10.3243 17.4574 10.4951 17.5715C10.6659 17.6856 10.8667 17.7465 11.0721 17.7465C11.3473 17.7458 11.6111 17.6361 11.8057 17.4415C12.0003 17.2469 12.1099 16.9832 12.1107 16.708Z"
        fill="#FCB817"
      />
      <path
        d="M18.5497 16.7082C18.55 16.5024 18.4893 16.3013 18.3753 16.13C18.2612 15.9588 18.099 15.8253 17.909 15.7463C17.7191 15.6673 17.51 15.6464 17.3082 15.6862C17.1064 15.726 16.9209 15.8248 16.7752 15.97C16.6295 16.1153 16.5302 16.3005 16.4898 16.5022C16.4493 16.7039 16.4696 16.913 16.5481 17.1032C16.6265 17.2934 16.7596 17.456 16.9304 17.5706C17.1013 17.6851 17.3023 17.7464 17.508 17.7467C17.7838 17.7468 18.0483 17.6375 18.2436 17.4428C18.4389 17.2481 18.5489 16.9839 18.5497 16.7082Z"
        fill="#FCB817"
      />
      <path
        d="M26.2009 14.042C25.9315 13.9948 25.6555 14.0013 25.3886 14.061C25.1218 14.1208 24.8693 14.2327 24.6458 14.3902C24.4223 14.5478 24.2321 14.7479 24.0861 14.9792C23.9401 15.2104 23.8413 15.4682 23.7952 15.7377C23.3475 18.3224 21.8914 20.6233 19.7472 22.1343C17.6031 23.6453 14.9465 24.2427 12.3618 23.795C9.77722 23.3473 7.47631 21.8912 5.96528 19.7471C4.45425 17.6029 3.85687 14.9463 4.30456 12.3617C4.31697 12.2904 4.32937 12.216 4.34487 12.1447C7.41563 13.3864 10.6988 14.0182 14.0111 14.0048C18.171 14.0037 22.2823 13.1104 26.0676 11.3851C26.5359 11.1506 26.8983 10.7479 27.0822 10.2575C27.2662 9.76713 27.2581 9.22538 27.0596 8.74072C26.261 6.7843 25.0314 5.03321 23.4623 3.61779C21.8932 2.20237 20.0251 1.15908 17.9971 0.565578C15.969 -0.0279214 13.8331 -0.156344 11.7485 0.189871C9.66396 0.536085 7.6843 1.34802 5.95694 2.56523C4.44868 3.62793 3.16453 4.97733 2.1778 6.53635C1.19108 8.09537 0.521122 9.8335 0.206181 11.6515C-0.10876 13.4694 -0.0625128 15.3316 0.342282 17.1317C0.747076 18.9318 1.50249 20.6345 2.56538 22.1426C3.62428 23.6546 4.97265 24.9414 6.53239 25.9285C8.09213 26.9157 9.83223 27.5835 11.6519 27.8934C12.4536 28.0326 13.2657 28.1032 14.0793 28.1042C17.3847 28.0946 20.5811 26.921 23.1073 24.7894C25.6335 22.6577 27.3281 19.7043 27.8936 16.4477C27.9875 15.9043 27.8621 15.3458 27.5448 14.8948C27.2274 14.4437 26.7441 14.137 26.2009 14.042ZM8.35335 5.96609C9.42356 5.21213 10.6327 4.678 11.9108 4.39462C13.1889 4.11124 14.5105 4.08425 15.7991 4.31521C17.0876 4.54616 18.3176 5.03048 19.4177 5.74012C20.5178 6.44975 21.4662 7.37059 22.2079 8.44931C19.055 9.50956 12.6067 10.9759 5.98484 8.3191C6.63333 7.40454 7.43456 6.60856 8.35335 5.96609Z"
        fill="#FCB817"
      />
      <path
        d="M14.2592 21.2502C15.3923 21.2526 16.4876 20.8427 17.3407 20.0969C17.5036 19.9542 17.6568 19.801 17.7995 19.6381C17.8452 19.5859 17.8682 19.5177 17.8635 19.4485C17.8589 19.3793 17.8269 19.3148 17.7747 19.2692C17.7225 19.2236 17.6543 19.2005 17.5851 19.2052C17.5159 19.2098 17.4514 19.2418 17.4058 19.294C17.2789 19.4395 17.1421 19.5763 16.9966 19.7032C16.5829 20.0636 16.1022 20.3389 15.582 20.5134C15.0619 20.6879 14.5124 20.7581 13.9651 20.7201C13.4177 20.6821 12.8833 20.5366 12.3922 20.2919C11.9011 20.0472 11.4631 19.7081 11.1032 19.294C11.0576 19.2418 10.9931 19.2098 10.9239 19.2052C10.8547 19.2005 10.7865 19.2236 10.7343 19.2692C10.6821 19.3148 10.6501 19.3793 10.6455 19.4485C10.6408 19.5177 10.6639 19.5859 10.7095 19.6381C11.1513 20.1452 11.6969 20.5515 12.3092 20.8296C12.9216 21.1078 13.5866 21.2512 14.2592 21.2502Z"
        fill="#FCB817"
      />
    </svg>
  );
}
