import React from "react";

export function IconOurPreschool({ size }) {
  return (
    <svg
      width={size ? size : 45}
      height={size ? size : 45}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5864 10.6823C27.4294 10.5321 27.2205 10.4482 27.0032 10.4482C26.7859 10.4482 26.5769 10.5321 26.4199 10.6823L7.00313 29.2311C6.92067 29.31 6.85506 29.4047 6.81029 29.5097C6.76551 29.6147 6.74249 29.7276 6.74262 29.8417L6.73946 47.2504C6.73946 48.1455 7.09504 49.004 7.72797 49.6369C8.36091 50.2698 9.21935 50.6254 10.1145 50.6254H20.25C20.6976 50.6254 21.1268 50.4476 21.4432 50.1312C21.7597 49.8147 21.9375 49.3855 21.9375 48.9379V34.5942C21.9375 34.3704 22.0264 34.1558 22.1846 33.9975C22.3429 33.8393 22.5575 33.7504 22.7813 33.7504H31.2188C31.4425 33.7504 31.6571 33.8393 31.8154 33.9975C31.9736 34.1558 32.0625 34.3704 32.0625 34.5942V48.9379C32.0625 49.3855 32.2403 49.8147 32.5568 50.1312C32.8732 50.4476 33.3025 50.6254 33.75 50.6254H43.8813C44.7764 50.6254 45.6349 50.2698 46.2678 49.6369C46.9008 49.004 47.2563 48.1455 47.2563 47.2504V29.8417C47.2565 29.7276 47.2334 29.6147 47.1887 29.5097C47.1439 29.4047 47.0783 29.31 46.9958 29.2311L27.5864 10.6823Z"
        fill="#636466"
      />
      <path
        d="M51.7757 25.7502L43.8866 18.2029V6.75C43.8866 6.30245 43.7088 5.87323 43.3924 5.55676C43.0759 5.24029 42.6467 5.0625 42.1991 5.0625H37.1366C36.6891 5.0625 36.2598 5.24029 35.9434 5.55676C35.6269 5.87323 35.4491 6.30245 35.4491 6.75V10.125L29.3404 4.28414C28.7687 3.70617 27.9186 3.375 27 3.375C26.0845 3.375 25.2366 3.70617 24.6649 4.2852L2.23172 25.7481C1.5757 26.3809 1.49344 27.4219 2.09039 28.1074C2.2403 28.2805 2.42384 28.4212 2.62986 28.5211C2.83588 28.6209 3.06007 28.6778 3.28877 28.6883C3.51748 28.6987 3.74592 28.6625 3.96019 28.5819C4.17446 28.5013 4.37009 28.3779 4.53516 28.2192L26.4199 7.30688C26.577 7.15667 26.7859 7.07283 27.0032 7.07283C27.2205 7.07283 27.4294 7.15667 27.5864 7.30688L49.4733 28.2192C49.7957 28.5284 50.2275 28.6971 50.6742 28.6884C51.1208 28.6797 51.5457 28.4943 51.8558 28.1728C52.5034 27.502 52.4496 26.3946 51.7757 25.7502Z"
        fill="#FDB913"
      />
    </svg>
  );
}
