import React from 'react'

export default function More({ onClick }) {

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} onClick={onClick}>
            <g filter="url(#filter0_b)">
                <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#636466" fillOpacity="0.5" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.82125 11.6328C6.70885 11.6328 7.4284 10.9475 7.4284 10.1021C7.4284 9.25681 6.70885 8.57153 5.82125 8.57153C4.93365 8.57153 4.21411 9.25681 4.21411 10.1021C4.21411 10.9475 4.93365 11.6328 5.82125 11.6328Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3213 11.6328C11.2089 11.6328 11.9284 10.9475 11.9284 10.1021C11.9284 9.25681 11.2089 8.57153 10.3213 8.57153C9.43365 8.57153 8.71411 9.25681 8.71411 10.1021C8.71411 10.9475 9.43365 11.6328 10.3213 11.6328Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.8215 11.6328C15.7091 11.6328 16.4286 10.9475 16.4286 10.1021C16.4286 9.25681 15.7091 8.57153 14.8215 8.57153C13.9339 8.57153 13.2144 9.25681 13.2144 10.1021C13.2144 10.9475 13.9339 11.6328 14.8215 11.6328Z" fill="white" />
            <defs>
                <filter id="filter0_b" x="-4" y="-4" width="28" height="28" filterUnits="userSpaceOnUse">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}
