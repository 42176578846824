import {
  SET_LANGUAGE,
  SET_SELECTED_PRESCHOOL,
  SET_PRESCHOOL_WEEKLY_PLANNING,
  SET_SITEVAR_EVENT_DATESPAN,
  SET_SITEVAR_EMPLOYEMENT_DATESPAN,
  SET_SITEVAR_REPORT_DATESPAN,
} from "./types";

export const setLanguage = (language) => (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: language,
  });
};

export const setSelectedPreschool = (preschool) => (dispatch) => {
  localStorage.setItem("selectedPreschool", JSON.stringify(preschool));
  dispatch({ type: SET_PRESCHOOL_WEEKLY_PLANNING, payload: [] });
  dispatch({ type: SET_SELECTED_PRESCHOOL, payload: preschool });
};

export const setEventDateSpan = (dateFrom, dateTo) => (dispatch) => {
  dispatch({
    type: SET_SITEVAR_EVENT_DATESPAN,
    payload: {
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  });
};

export const setEmployementsDateSpan = (dateFrom, dateTo) => (dispatch) => {
  dispatch({
    type: SET_SITEVAR_EMPLOYEMENT_DATESPAN,
    payload: {
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  });
};

export const setReportDateSpan = (dateFrom, dateTo) => (dispatch) => {
  dispatch({
    type: SET_SITEVAR_REPORT_DATESPAN,
    payload: {
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  });
};
