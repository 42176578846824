import React from "react";
import Button from "./Button";
import { IconCalender } from "../../../assets/iconCalender";
import { IconMonthlyReports } from "../../../assets/iconMonthlyReports";
import { IconOurPreschool } from "../../../assets/iconOurPreschool";
import { IconUpcomingWeek } from "../../../assets/iconUpcomingWeek";
import { useHistory } from "react-router-dom";
import CircleNumber from "./CircleNumber";
import useResizeWindow from "../../../hooks/useResizeWindow";

export default function HomeScreenButtons({ plannedActivities }) {
  const windowDimensions = useResizeWindow();
  const layout =
    windowDimensions.width > windowDimensions.height ? "landscape" : "portrait";

  let history = useHistory();
  const navigate = (url) => {
    history.push(url);
  };

  const iconSize =
    windowDimensions.width > 600 ? 600 / 7 : windowDimensions.width / 7;

  const UpcommingWeekButtonIcon = (
    <CircleNumber
      icon={<IconUpcomingWeek />}
      number={plannedActivities.length}
      size={iconSize}
    />
  );

  return (
    <div
      style={{
        flex: 1,
        display: "grid",
        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
        gridTemplateRows: "repeat(2,minmax(0,1fr))",
        columnGap: 8,
        rowGap: 8,
        padding: layout === "portrait" ? 8 : 0,
        paddingLeft: layout !== "portrait" ? 8 : 0,
        cursor: "pointer",
      }}
    >
      <Button
        title="Kommunikation & kalender"
        caption="Se och skapa händelser samt kommunicera med arbetslaget"
        iconComponent={<IconCalender size={iconSize} />}
        onPress={() => navigate("/events")}
      />
      <Button
        title="Månadsrapporter"
        caption="Förskolans resultat, reflektioner och analyser"
        iconComponent={<IconMonthlyReports size={iconSize} />}
        onPress={() => navigate("/reports")}
      />
      <Button
        title="Vår förskola"
        caption="Förskolans mål, projekt / tema och personal"
        onPress={() => navigate("/preschool")}
        iconComponent={<IconOurPreschool size={iconSize} />}
      />
      <Button
        title="Vår vecka"
        caption="Planerade aktiviteter denna vecka"
        onPress={() => navigate("/plannedactivities")}
        iconComponent={UpcommingWeekButtonIcon}
      />
    </div>
  );
}
