import React from "react";

export default function User({ size }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 100 100"
    >
      <path
        d="M74.399,46.201h-5.994C63.677,50.893,57.172,53.8,49.999,53.8c-7.172,0-13.677-2.907-18.405-7.599H25.6
	c-7.7,0-13.941,6.243-13.941,13.943v20.914C11.658,88.758,17.899,95,25.6,95h48.8c7.7,0,13.942-6.242,13.942-13.942V60.144
	C88.342,52.443,82.1,46.201,74.399,46.201z"
        fill="#636466"
      />
      <circle cx="49.999" cy="27.657" r="22.657" fill="#636466" />
    </svg>
  );
}
