import { combineReducers } from "redux";
import authReducer from "./authReducer";
import siteVar from "./siteVar";
import pedagogyReducer from "./pedagogyReducer";
import preschoolDataReducer from "./preschoolDataReducer";
import preschoolStats from "./preschoolStatsReducer";
import roles from "./rolesReducer";
import errors from './errorsReducer'

// import errorReducer from './errorReducer';
// import employeesReducer from './employeesReducer';

export default combineReducers({
  auth: authReducer,
  siteVar: siteVar,
  // errors: authReducer,
  pedagogy: pedagogyReducer,
  //   employees: employeesReducer
  preschoolData: preschoolDataReducer,
  preschoolStats: preschoolStats,
  roles: roles,
  errors: errors
});
