import React, { useState } from "react";

export default function ContextualMenuItem({ item, closeContextualMenu }) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  return (
    <div
      onClick={() => {
        item.onClick(item.content);
        closeContextualMenu();
      }}
      style={{
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        height: 44,
        alignItems: "center",
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: isMouseOver ? "#FCF8DB" : "white",
      }}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {item.icon}
      <div style={{ marginLeft: 25 }}>{item.text}</div>
    </div>
  );
}
