import React from "react";

export default function Close({ onClick, size }) {
  return (
    <div onClick={onClick}>
      <svg
        width={size ? size : 34}
        height={size ? size : 34}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.4375 24.4375L9.5625 9.5625"
          stroke="#636466"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.4375 9.5625L9.5625 24.4375"
          stroke="#636466"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
