import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import validator from "validator";
import generator from "generate-password";
import { useDispatch } from "react-redux";

import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

import TextField from "../common/inputs/textField/TextField";
import Picker from "../common/inputs/picker/Picker";
import ButtonWithIcon from "../common/buttons/buttonWithIcon/ButtonWithIcon";

import Select from "react-dropdown-select";

import { getPreschoolEmployees } from "../../../store/actions";

export default function CreateEmployeeAndEmployement({
  selectedPreschool,
  employementsDateSpan,
  roles,
}) {
  const dispatch = useDispatch();
  const [selectedSection, setSelectedSection] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(
    generator.generate({
      length: 10,
      numbers: true,
      lowercase: true,
      uppercase: true,
      strict: true,
      excludeSimilarCharacters: true,
    })
  );
  const [selectedRole, setSelectedRole] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment().format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState("");

  const [errors, setErrors] = useState([]);

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const filter = [
    "5dbfe0fac9dffb00105a465f",
    "5dbfe105c9dffb00105a4660",
    "5dbfe10bc9dffb00105a4661",
    "5fce26d95b67ba5db27f2982",
  ];

  const onToggleSection = (item) => {
    if (selectedSection[0] === item) {
      setSelectedSection([]);
    } else {
      setSelectedSection(item);
    }
  };

  const onToggleRole = (item) => {
    if (selectedRole[0] === item) {
      setSelectedRole([]);
    } else {
      setSelectedRole(item);
    }
  };

  const validation = (data) => {
    let err = {};

    if (!validator.isLength(data.name, { min: 3 })) {
      err.name = "Namn måste innehålla minst 3 tecken";
    }

    if (!validator.isEmail(data.email)) {
      err.email = "Ogiltig email adress";
    }

    if (!validator.isLength(data.password, { min: 6, max: 30 })) {
      err.password =
        "Lösenordet måste innehålla minst 6 bokstäver eller siffror";
    }

    if (!data.role) {
      err.role = "Välj en roll";
    }

    if (!data.organisation) {
      err.organisation = "Välj en avdelning";
    }

    if (!validator.isDate(data.validFrom)) {
      err.validFrom = "Från-datum är ogiltig";
    }

    if (!validator.isDate(data.validTo) && data.validTo) {
      err.validTo = "Till-datum är ogiltig";
    }

    if (data.validTo && validator.isBefore(data.validTo, data.validFrom)) {
      err.validTo = "Till-datum är tidigare från datum";
      err.validFrom = "Från-datum är senare till datum";
    }

    setErrors(err);
    return err;
  };

  const onSubmit = () => {
    setIsSubmitButtonDisabled(true);
    const data = {
      name: name,
      email: email,
      password: password,
      password2: password,
      role: selectedRole.length > 0 ? selectedRole[0]._id : null,
      origin: "section",
      organisation: selectedSection.length > 0 ? selectedSection[0]._id : null,
      preschoolId: selectedPreschool._id,
      validFrom: dateFrom,
      validTo: dateTo !== "" ? dateTo : null,
    };

    const valid = validation(data);
    if (!Object.keys(valid).length) {
      axios
        .post("/v2/user/registerbyprincipal", data)
        .then((res) => {
          alert(
            `${res.data.name}s konto är skapat! Lösenord skickades till ${res.data.email}.`
          );
          dispatch(
            getPreschoolEmployees(
              selectedPreschool._id,
              employementsDateSpan.dateFrom,
              employementsDateSpan.dateTo
            )
          );
          setSelectedRole([]);
          setSelectedSection([]);
          setName("");
          setEmail("");
          setPassword(
            generator.generate({
              length: 10,
              numbers: true,
              lowercase: true,
              uppercase: true,
              strict: true,
              excludeSimilarCharacters: true,
            })
          );
          setDateFrom(moment().format("YYYY-MM-DD"));
          setDateTo("");
          setIsSubmitButtonDisabled(false);
        })
        .catch((err) => {
          setIsSubmitButtonDisabled(false);
          console.log(err.response.data);
        });
    } else {
      setIsSubmitButtonDisabled(false);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <div
        style={{
          fontSize: 18,
          fontWeight: "bold",
          paddingBottom: 10,
        }}
      >
        Nytt pedagogkonto på {selectedPreschool.name}
      </div>
      <div style={{ fontSize: 10, fontStyle: "italic", paddingBottom: 10 }}>
        Här lägger du till och tar bort pedagoger från din förskola. När du
        lägger till en pedagog går ett automatiskt mail med information samt
        inloggingsuppgifter till den mail du anger. Vill du flytta en pedagog
        mellan två avdelningar behöver du ta bort pedagogen på den ena
        avdelningen och sedan lägga till pedagogen på den nya avdelningen,
        pedagogen behåller sitt nuvarande lösenord. Vill du lägga till en
        pedagog till två avdelningar behöver du lägga till pedagogen till båda
        avdelningarna. Vill du lägga till en övergripande roll behöver du
        kontakta edChild på{" "}
        <a href="mailto:hello@edchild.com?subject=Skapa övergripande roll">
          hello@edchild.com
        </a>
      </div>
      <div>
        <div
          style={{
            backgroundColor: "#FCE59F",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <TextField
            title="Namn"
            value={name}
            placeHolder="Namn"
            onChange={(e) => setName(e.target.value)}
            error={errors.name}
          />

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% 50%",
              gap: 10,
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <div style={{ gridColumn: 1 }}>
              <TextField
                title="Email"
                value={email}
                placeHolder="Email"
                onChange={(e) => setEmail(e.target.value)}
                error={errors.email}
                textInputStyle={{ fontWeight: "normal" }}
                autoComplete="off"
              />
            </div>{" "}
            <div style={{ gridColumn: 2 }}>
              {/* <TextField
                title="Lösenord"
                value={password}
                placeHolder="Lösenord"
                onChange={(e) => setPassword(e.target.value)}
                error={errors.password}
                type="password"
                autoComplete="new-password"
              /> */}
            </div>
            <div style={{ gridColumn: 1 }}>
              <div>Avdelning</div>
              <Select
                options={selectedPreschool.sections}
                onChange={(values) => onToggleSection(values)}
                values={selectedSection}
                labelField="name"
                valueField="_id"
                placeholder="Välj avdelning"
                style={{
                  borderRadius: 6,
                  borderWidth: 0,
                  padding: 8,
                  backgroundColor: "#FCF8DB",
                }}
                color="#FCF8DB"
              />
              <div
                style={{
                  marginTop: 3,
                  fontSize: 12,
                  fontStyle: "italic",
                  color: "red",
                }}
              >
                {errors.organisation && errors.organisation}
              </div>
            </div>
            <div style={{ gridColumn: 2 }}>
              <div>Roll</div>
              <Select
                options={roles
                  .filter((item) => !filter.includes(item._id))
                  .sort((a, b) => a.sort - b.sort)}
                onChange={(values) => onToggleRole(values)}
                values={selectedRole}
                labelField="name"
                valueField="_id"
                placeholder="Välj roll"
                style={{
                  borderRadius: 6,
                  borderWidth: 0,
                  padding: 8,
                  backgroundColor: "#FCF8DB",
                }}
                color="#FCF8DB"
              />
              <div
                style={{
                  marginTop: 3,
                  fontSize: 12,
                  fontStyle: "italic",
                  color: "red",
                }}
              >
                {errors.role && errors.role}
              </div>
            </div>
            <div style={{ gridColumn: 1 }}>
              <TextField
                title="Från"
                value={dateFrom}
                placeHolder="åååå-mm-dd"
                onChange={(e) => setDateFrom(e.target.value)}
                inputStyle={{ fontWeight: "normal", fontSize: 14 }}
                size={12}
                error={errors.validFrom}
              />
            </div>
            <div style={{ gridColumn: 2 }}>
              <TextField
                title="Till (kan vara tom)"
                value={dateTo}
                placeHolder="åååå-mm-dd"
                onChange={(e) => setDateTo(e.target.value)}
                inputStyle={{ fontWeight: "normal", fontSize: 14 }}
                size={12}
                error={errors.validTo}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <ButtonWithIcon
            text={isSubmitButtonDisabled ? "Sparar " : "Lägg till"}
            icon={
              !isSubmitButtonDisabled ? <div>+</div> : <BeatLoader size={8} />
            }
            iconsStyle={{ marginLeft: 5 }}
            onClick={() => onSubmit()}
            style={{ marginTop: 10 }}
            disabled={isSubmitButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
}
