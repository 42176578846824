import React, { useState, useEffect, useRef } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import useResizeWindow from "../../../../hooks/useResizeWindow";

// import "./radar.css";

export default function PedagogicalAreasRadar({
  pedagogicalArea,
  stats,
  preschool,
  maxValue,
}) {
  const [width, setWidth] = useState(0);
  const [milestones, setMilestones] = useState([]);
  const [data, setData] = useState([]);
  const elementRef = useRef(null);

  const windowDimensions = useResizeWindow();

  useEffect(() => {
    let items = [];
    pedagogicalArea.centralcontent.map((ct) =>
      ct.milestones.map((ms) => items.push({ name: ms.shortName, _id: ms._id }))
    );
    setMilestones(items);
  }, [pedagogicalArea]);

  useEffect(() => {
    if (preschool) {
      let chartData = [...milestones];
      preschool.sections.map((section) => {
        chartData.map((item) => (item[section._id] = 0));
      });
      stats.map((plannedActivity) => {
        const section = plannedActivity.section;
        plannedActivity.documentation.achievedMilestones.map((item) => {
          const dataIndex = chartData.findIndex((cd) => cd._id === item);
          if (dataIndex !== -1) {
            const newData = chartData[dataIndex];
            newData[section] = newData[section] ? newData[section] + 1 : 1;
            chartData[dataIndex] = newData;
          }
        });
      });
      setData(chartData);
    }
  }, [milestones, stats, preschool]);

  const radars =
    preschool &&
    preschool.sections.map((section, index) => {
      return (
        <Radar
          name={section.name}
          dataKey={section._id}
          stroke={section.color}
          fill={section.color}
          fillOpacity={0.2}
          key={`${section._id}${index}Radar`}
          dot={true}
        />
      );
    });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, [elementRef]); //empty dependency array so it only runs once at render

  function customTick({ payload, x, y, textAnchor, stroke, radius }) {
    const fontSize = 12;
    const text =
      windowDimensions.width >= 800 ? payload.value : payload.index + 1;
    let strings = [];

    if (typeof text === "string" && text.split(" ").length > 2) {
      const textArray = text.split(" ");
      const numberOfSpaces = textArray.length - 1;
      const middle = Math.round(numberOfSpaces / 2);
      const string1 = [...textArray];

      string1.splice(middle, string1.length - middle);
      const string2 = [...textArray];

      string2.splice(0, middle);

      strings = [string1.join(" "), string2.join(" ")];
    }

    const singleLineComponent = (
      <g>
        <text
          width={30}
          radius={radius}
          stroke={stroke}
          x={x}
          y={y}
          className="recharts-text recharts-polar-angle-axis-tick-value"
          textAnchor={textAnchor}
        >
          <tspan
            x={x}
            dy="0em"
            width={30}
            style={{
              fontSize: fontSize,
              color: "#636466",
              fontFamily: "arialRounded!important",
            }}
          >
            {text}
          </tspan>
        </text>
      </g>
    );

    const multiLineComponent = (
      <g>
        <text
          width={30}
          radius={radius}
          stroke={stroke}
          x={x}
          y={y - 5}
          className="recharts-text recharts-polar-angle-axis-tick-value"
          textAnchor={textAnchor}
        >
          <tspan
            x={x}
            dy="0em"
            style={{
              fontSize: fontSize,
              color: "#636466",
              fontFamily: "arialRounded!important",
            }}
            width={30}
          >
            {strings[0]}
          </tspan>
        </text>
        <text
          width={30}
          radius={radius}
          stroke={stroke}
          x={x}
          y={y + 5}
          className="recharts-text recharts-polar-angle-axis-tick-value"
          textAnchor={textAnchor}
        >
          <tspan
            x={x}
            dy="0em"
            style={{
              fontSize: fontSize,
              color: "#636466",
              fontFamily: "arialRounded!important",
            }}
            width={30}
          >
            {strings[1]}
          </tspan>
        </text>
      </g>
    );

    return strings.length > 0 ? multiLineComponent : singleLineComponent;
  }

  return data.length > 0 ? (
    <div
      ref={elementRef}
      style={{
        borderRadius: 10,

        marginBottom: 40,
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: "#FCF8DB",
      }}
    >
      <div style={{ textAlign: "center", fontSize: 20 }}>
        {pedagogicalArea.name}
      </div>
      <ResponsiveContainer
        width={
          windowDimensions.width > 900 ? 900 - 20 : windowDimensions.width - 20
        }
        height={500}
      >
        <RadarChart outerRadius="85%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" tick={customTick} />
          <PolarRadiusAxis
            angle={30}
            domain={[-1, maxValue]}
            angle={90 - 360 / data.length}
          />
          {radars}
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
      {windowDimensions.width < 800 && (
        <ol type="1">
          {data.map((item) => (
            <li>{item.name}</li>
          ))}
        </ol>
      )}
    </div>
  ) : (
    <div ref={elementRef}>laddar...</div>
  );
}
