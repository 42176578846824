import { SET_PEDAGOGICAL_AREA, RESET_STORE } from "../actions/types";

const initialState = {
  pedagogy: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PEDAGOGICAL_AREA:
      return {
        ...state,
        pedagogy: action.payload,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
