import React from "react";
import Back from "../../../../assets/Back";

export default function BackButton({ onClick }) {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Back />
      <div style={{ marginLeft: 10, color: "#FCB817" }}>Tillbaka</div>
    </div>
  );
}
