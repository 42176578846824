import React, { useState } from "react";
import moment from "moment";
import More from "../../../assets/More";
import ContextualMenu from "../contextualMenu/ContextualMenu";
import Delete from "../../../assets/Delete";
import Pencil from "../../../assets/Pencil";

export default function EventWithDateContainer({
  event,
  style,
  menuItems,
  onDelete,
  onEdit,
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const SingleDate = ({ date }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 67,
          fontSize: 20,
          fontWeight: "bold",
        }}
      >
        {moment(date).format("ddd")}
        <br />
        {moment(date).format("DD")}
      </div>
    );
  };

  const closeContextualMenu = () => {
    setIsMenuVisible(false);
  };

  const contextualMenuItems = [
    {
      _id: event._id,
      icon: <Delete />,
      text: "Radera",
      onClick: onDelete,
      content: event,
    },
    {
      _id: event._id,
      icon: <Pencil />,
      text: "Redigera",
      onClick: onEdit,
      content: event,
    },
  ];

  const DateSpan = ({ dateFrom, dateTo }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 67,
          fontSize: 16,
          fontWeight: "bold",
        }}
      >
        {moment(dateFrom).format("DD")} {moment(dateFrom).format("MMM")}
        <br />
        {moment(dateTo).format("DD")} {moment(dateTo).format("MMM")}
      </div>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        ...style,
      }}
    >
      {isMenuVisible && (
        <ContextualMenu
          items={contextualMenuItems}
          closeContextualMenu={closeContextualMenu}
        />
      )}
      {event.type === "calendar" ? (
        <SingleDate date={event.dateFrom} />
      ) : (
        <DateSpan dateFrom={event.dateFrom} dateTo={event.dateTo} />
      )}
      <div
        style={{
          flex: 1,
          backgroundColor: "#FCF8DB",
          borderRadius: 10,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 15,
            paddingRight: 15,
            height: 32,
            backgroundColor: "#FCB817",
            fontSize: 16,
            fontWeight: "bold",
            color: "white",
            alignItems: "center",
          }}
        >
          <div>{event.title ? event.title : "Inget namn"}</div>
          <More
            event={event}
            onClick={() => setIsMenuVisible(!isMenuVisible)}
          />
        </div>
        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 5,
            paddingBottom: 5,
          }}
          dangerouslySetInnerHTML={{ __html: event.text }}
        />
      </div>
    </div>
  );
}
