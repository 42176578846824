import React, { useState } from "react";
import convertUriToBase64 from "../../../utils/urlToBase64";
import section from "../../../utils/getSectionById";
//import _ from "lodash";

export default function CarouselCard({
  item,
  pedagogycalAreas,
  selectedPreschool,
}) {
  let cardImage = item.images.length > 0 ? item.images[0].path : "";
  const [backgroundImage, setBackgroundImage] = useState("");

  const milestones = item.parentActivity.milestones;

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const findMilestone = (milestone) => {
    let result;
    pedagogycalAreas.forEach((pa) => {
      pa.centralcontent.forEach((cc) => {
        cc.milestones.forEach((ms) => {
          if (ms._id === milestone) {
            result = {
              milestoneShortName: ms.shortName,
              milestoneId: ms._id,
              pedagogicalAreaName: pa.name,
              pedagogicalAreaId: pa._id,
            };
          }
        });
      });
    });
    return result;
  };

  const milestonesDetail = milestones.map((milestone) =>
    findMilestone(milestone)
  );

  const pedagogicalAreasSorted = groupBy(milestonesDetail, "pedagogicalAreaId");

  const milestoneComponent = [];

  for (const paItem in pedagogicalAreasSorted) {
    const info = pedagogicalAreasSorted[paItem];

    milestoneComponent.push(
      <div
        key={
          "milestonecomponentCarousel" + item._id + milestoneComponent.length
        }
      >
        <div style={{ fontWeight: "bold" }}>{info[0].pedagogicalAreaName}</div>
        {info.map((i, index) => (
          <div
            style={{ fontSize: 12 }}
            key={i._id + index.toString() + item._id}
          >
            {i.milestoneShortName}
          </div>
        ))}
      </div>
    );
  }

  if (item.images.length > 0) {
    convertUriToBase64(cardImage)
      .then((result) => setBackgroundImage(result))
      .catch((err) => console.log(err));
  } else {
    const milestoneId = item.parentActivity.milestones[0];
    pedagogycalAreas.map((pedagogicalArea) => {
      return pedagogicalArea.centralcontent.map((centralContent) => {
        return centralContent.milestones.map((milestone, index) => {
          if (milestone._id === milestoneId && backgroundImage === "") {
            return setBackgroundImage(pedagogicalArea.header);
          } else {
            return null;
          }
        });
      });
    });
  }

  const background = backgroundImage
    ? backgroundImage
    : pedagogycalAreas[0].header;

  const cardBackground = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    flex: 1,
    borderRadius: 16,
    marginRight: 8,
    marginLeft: 8,
    overflow: "hidden",
  };

  return (
    <div style={cardBackground}>
      <div
        style={{
          // background: "rgb(0,0,0)",
          background:
            "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
          color: "white",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <h2 style={{ opacity: 1, zIndex: 100 }}>{item && item.name}</h2>
        {selectedPreschool && item && (
          <h4>{section(item, selectedPreschool).name}</h4>
        )}
        {milestoneComponent}
      </div>
    </div>
  );
}
