import {
  SET_PRESCHOOL_TRENDS,
  SET_PRESCHOOL_DCCUMENTED_ACTIVITIES,
  SET_PRESCHOOL_GOALS_SUMMARY,
  SET_PRESCHOOL_ANALYSE,
  RESET_STORE,
} from "../actions/types";

const initialState = {
  trends: [],
  documentedActivities: [],
  analyse: [],
  preschoolGoals: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PRESCHOOL_TRENDS:
      return {
        ...state,
        trends: action.payload,
      };
    case SET_PRESCHOOL_DCCUMENTED_ACTIVITIES:
      const payload = action.payload;
      return {
        ...state,
        documentedActivities: payload.filter(
          (item) => item.documentation.createddate
        ),
      };
    case SET_PRESCHOOL_GOALS_SUMMARY:
      return {
        ...state,
        preschoolGoals: action.payload,
      };
    case SET_PRESCHOOL_ANALYSE:
      return {
        ...state,
        analyse: action.payload,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
