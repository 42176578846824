import React from "react";

export default function CircleWithNumber({ title, number, caption }) {
  return (
    <div
      style={{
        flex: 3,
        justifyContent: "space-evenly",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div style={{ lineHeight: "20px", fontSize: 14 }}>{title}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            width: 53,
            height: 53,
            backgroundColor: "#FCE59F",
            borderRadius: 53 / 2,
            justifyContent: "center",
            alignItems: "center",
            fontSize: 22,
            fontWeight: "bold",
          }}
        >
          {number}
        </div>
      </div>
      <div style={{ fontSize: 11 }}>{caption}</div>
    </div>
  );
}
