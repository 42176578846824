import React, { useState } from "react";
import moment from "moment";
import More from '../../../assets/More'
import ContextualMenu from '../contextualMenu/ContextualMenu'
import Delete from '../../../assets/Delete'
import Pencil from '../../../assets/Pencil'

export default function PreschoolGoal({ goal, style, menuItems, onEdit, onDelete }) {
  const [isMenuVisible, setIsMenuVisible] = useState(false)


  const closeContextualMenu = () => {
    setIsMenuVisible(false)
  }

  const contextualMenuItems = [
    //   {
    //   _id: goal._id,
    //   icon: <Delete />,
    //   text: 'Radera',
    //   onClick: onDelete,
    //   content: goal
    // }, 
    {
      _id: goal._id,
      icon: <Pencil />,
      text: 'Redigera',
      onClick: onEdit,
      content: goal
    }]


  return (
    <div style={{ display: "flex", flexDirection: "row", position: 'relative', ...style }}>
      {isMenuVisible && <ContextualMenu items={contextualMenuItems} closeContextualMenu={closeContextualMenu} />}
      <div
        style={{
          flex: 1,
          backgroundColor: "#FCF8DB",
          borderRadius: 10,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 15,
            paddingRight: 15,
            height: 45,
            backgroundColor: "#FCB817",
            fontSize: 16,
            fontWeight: "bold",
            color: "white",
            alignItems: "center",
          }}
        >
          <div>
            <div>{goal.name}</div>
            <div style={{ fontSize: 12 }}>
              {moment(goal.startDate).format("YYYY-MM-DD")} -{" "}
              {moment(goal.endDate).format("YYYY-MM-DD")}
            </div>
          </div>
          <More items={menuItems} onClick={() => setIsMenuVisible(!isMenuVisible)} />
        </div>
        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 5,
            paddingBottom: 15,
          }}
        >
          <div style={{ fontSize: 14, fontWeight: "bold", marginTop: 10 }}>
            Kort beskrivning
          </div>
          {goal.description}
          {goal.purpose && (
            <div>
              <div style={{ fontSize: 14, fontWeight: "bold", marginTop: 10 }}>
                Varför? - Syfte
              </div>
              {goal.purpose}
            </div>
          )}
          {goal.content && (
            <div>
              <div style={{ fontSize: 14, fontWeight: "bold", marginTop: 10 }}>
                Vad? - Mål
              </div>
              {goal.content}
            </div>
          )}
          {goal.how && (
            <div>
              <div style={{ fontSize: 14, fontWeight: "bold", marginTop: 10 }}>
                Hur? - Tillvägagångssätt
              </div>
              {goal.how}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
