import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const edchild = require("../../../assets/e-edchild.svg");

export default function EmptyCard({ item }) {
  const cardBackground = {
    display: "flex",
    flex: 1,
    borderRadius: 16,
    marginRight: 8,
    marginLeft: 8,
    overflow: "hidden",
    backgroundImage: `url(${edchild})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "50%",
    backgroundPosition: "center",
  };

  return (
    <div style={cardBackground}>
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
          color: "white",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
          paddingRight: 8,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <ScaleLoader
          height={35}
          width={4}
          radius={2}
          margin={2}
          loading={true}
          color={"#FCF8DB"}
        />
      </div>
    </div>
  );
}
