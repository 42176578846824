import React, { useState, useEffect } from "react";
import Ff from "../../../assets/ff";
import TextField from "../../newLayout/common/inputs/textField/TextField";
import Button from "../../newLayout/common/buttons/Button";
import {
  loginUser,
  loginFaceBookUser,
} from "../../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

export default function Login() {
  const dispatch = useDispatch();
  dispatch({ type: "RESET_STORE", payload: "" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isEmailFieldDisabled, setIsEmailFieldDisabled] = useState(false);
  const [submitMode, setSumbitMode] = useState("check");
  const [isPasswordFieldVisible, setIsPasswordFieldVisible] = useState(false);
  const [sumbit, setSumbit] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  const errors = useSelector((state) => state.errors.login);

  const ckeckLogin = () => {
    setIsEmailFieldDisabled(true);
    setIsFetching(true);
    axios
      .post(`/v2/user/unifiedLogin2`, { email: email })
      .then((res) => {
        switch (res.data.authType) {
          // case "facebook":
          //   onCreateAccountWithFaceBook();
          //   break;
          // case "apple":
          //   onAppleButtonPress();
          //   break;
          case "password":
            setIsPasswordFieldVisible(true);
            setSumbitMode("login");
            break;
          // case "create":
          //   setIsPasswordFieldVisible(true);
          //   setSumbitMode("create");
          //   break;
          case "federated":
            const config = res.data.authConfig;
            azureLogin(config.config);
            break;
          default:
            console.log("something strange has happen");
        }
        setIsFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setIsEmailFieldDisabled(false);
        setIsFetching(false);
      });
  };

  const azureLogin = async (config) => {
    setIsFetching(true);

    const publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: config.clientId,
        authority: `https://login.microsoftonline.com/${config.tenantId}`,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      },
    });

    var request = {
      scopes: ["User.Read", "TeamMember.Read.All"],
    };

    publicClientApplication
      .acquireTokenPopup(request)
      .then(async (tokenResponse) => {
        try {
          const result = tokenResponse;
          const headers = {
            headers: {
              "Content-type": "application/json",
              Authorization: `${result.tokenType} ${result.accessToken}`,
            },
          };

          axios
            .post("/v2/user/azurelogin", null, headers)
            .then(async (response) => {
              dispatch(loginFaceBookUser(response.data.token));
              console.log("successfull login with azure");
              //setIsFetching(false)
            })
            .catch((err) => {
              console.log(err);
              // onCancel();
              setIsFetching(false);
              // Alert.alert(
              //   "Fel",
              //   `Server svarar: ${err.response.status}: ${err.response.data}`
              // );
            });
        } catch (error) {
          // onCancel();
          console.log(error);
          setIsFetching(false);
        }
      })
      .catch(async (error) => {
        console.log(error);
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails

          return publicClientApplication.acquireTokenPopup(request);
        }
      })
      .catch((error) => {
        console.log(error);
        //handleError(error);
      });
  };

  // const onSubmit = () => {
  //   dispatch(loginUser({ email: email, password: password }));
  // };

  const loginWithPassword = () => {
    setIsFetching(true);
    setSumbit(false);
    setSubmitButtonEnabled(false);
    const userData = {
      email: email,
      password: password,
    };

    dispatch(loginUser(userData));
    setIsFetching(false);
    setSubmitButtonEnabled(true);
    setIsPasswordFieldVisible(false);
    // .then(() => {
    //   if (errors) {
    //     setSubmitButtonEnabled(true);
    //     setIsFetching(false);
    //   } else {
    //     onCancel();
    //   }
    // })
    // .catch((err) => {
    //   setIsFetching(false);
    //   setSubmitButtonEnabled(true);
    // });
  };

  const onSubmit = () => {
    setIsFetching(true);
    switch (submitMode) {
      case "check":
        ckeckLogin();
        break;
      case "login":
        loginWithPassword();
        break;
      // case "create":
      //   createAccount();
      //   break;
      default:
        ckeckLogin();
    }
  };

  const onCancel = () => {
    setIsPasswordFieldVisible(false);
    setIsEmailFieldDisabled(false);
    setSubmitButtonEnabled(true);
    setSumbitMode("check");
    setEmail("");
    setPassword("");
    setIsFetching(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100vw",
        maxWidth: 600,
        height: "100vh",
      }}
    >
      {/* <div
        style={{
          display: "flex",
          backgroundColor: "#FCB817",
          height: 74,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Ff />
      </div> */}
      <div> </div>
      <div
        style={{
          marginLeft: 26,
          marginRight: 26,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1, maxWidth: 350 }}>
          <div style={{ marginBottom: 25, fontSize: 22, fontWeight: "bold" }}>
            Logga in
          </div>
          <div
            style={{
              flex: 1,
              backgroundColor: "white",
              padding: 28,
              borderRadius: 10,
              boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
            }}
          >
            <TextField
              title="Användarnamn"
              value={email}
              style={{ marginBottom: 10 }}
              inputStyle={{
                backgroundColor: "white",
                border: "1px solid rgba(99, 100, 102, 1)",
                fontWeight: "normal",
              }}
              onChange={(e) => setEmail(e.target.value)}
              error={errors.email && errors.email}
            />
            {isPasswordFieldVisible && (
              <TextField
                title="Lösenord"
                value={password}
                style={{ marginBottom: 10 }}
                inputStyle={{
                  backgroundColor: "white",
                  border: "1px solid rgba(99, 100, 102, 1)",
                  fontWeight: "normal",
                }}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                error={errors.password && errors.password}
              />
            )}
            <Button
              text={isPasswordFieldVisible ? "Logga in" : "Nästa"}
              style={{ height: 40 }}
              onClick={() => onSubmit()}
            />
          </div>
        </div>
      </div>
      <div> </div>
      {/* <div
        style={{ cursor: "pointer", height: 100, textAlign: "center" }}
        onClick={() => alert("Oj!")}
      >
        Glömt lösenord?
      </div> */}
      <div style={{ cursor: "pointer", height: 100, textAlign: "center" }}>
        Our terms of use and privacy policy can be found{" "}
        <a href="https://www.edchild.com/fokusforskola/villkor" target="_blank">
          here
        </a>
        .
      </div>
    </div>
  );
}
