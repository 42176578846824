import React from "react";

export default function TextArea({
  title,
  value,
  onChange,
  placeHolder,
  error,
  name,
  rows,
  style,
  inputStyle,
  size,
  caption,
}) {
  const containerStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    ...style,
  };
  const textInputStyle = {
    flex: 1,
    borderRadius: 6,
    borderWidth: 0,
    fontSize: 16,
    fontWeight: "bold",
    padding: 8,
    backgroundColor: "#FCF8DB",
    color: "#636466",
    fontFamily: "arialRounded",
    ...inputStyle,
  };
  return (
    <div style={containerStyle}>
      {title && <div style={{ marginBottom: 3 }}>{title}</div>}
      <textarea
        style={textInputStyle}
        value={value}
        placeholder={placeHolder}
        onChange={onChange}
        rows={rows ? rows : 4}
      />
      {caption && (
        <div
          style={{
            marginTop: 3,
            fontSize: 12,
            fontStyle: "italic",
          }}
        >
          {caption}
        </div>
      )}
      {error && (
        <div
          style={{
            marginTop: 3,
            fontSize: 12,
            fontStyle: "italic",
            color: "red",
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
}
