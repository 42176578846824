import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedPreschool } from "../../../store/actions";
import Dropdown from "../../newLayout/dropdown/Dropdown";
import hamburger from "../../../utils/images/hamburgermenu.png";
import BackButton from "./backButton/BackButton";
import HomeButton from "./homeButton/HomeButton";
import MenuExtended from "../menuExtended/MenuExtended";
import "./header.css";

export default function Header({ title }) {
  const history = useHistory();

  const dispatch = useDispatch();
  const userAuthorizations = useSelector(
    (state) => state.auth.userAuthorization
  );
  const { selectedPreschool } = useSelector((state) => state.siteVar);

  const changePreschool = (preschool) => {
    dispatch(setSelectedPreschool(preschool));
  };

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      {isMenuVisible && (
        <MenuExtended onClickClose={() => setIsMenuVisible(false)} />
      )}
      <div
        style={{
          // display: "flex",
          // justifyContent:
          //   history.location.pathname !== "/dashboard"
          //     ? "space-between"
          //     : "flex-end",
          display: "grid",
          gridTemplateColumns: "33% 33% 34%",
          paddingTop: 24,
          paddingRight: 12,
          paddingLeft: 12,
          paddingBottom: 24,
          cursor: "pointer",
        }}
      >
        <div>
          {history.location.pathname !== "/dashboard" && (
            <BackButton onClick={() => history.goBack(-1)} />
          )}
        </div>
        <div>
          {history.location.pathname !== "/dashboard" && (
            <HomeButton
              onClick={() => history.push("/dashboard")}
              width={20}
              height={20}
            />
          )}
        </div>
        <div style={{ textAlign: "right" }}>
          <img
            src={hamburger}
            onClick={() => setIsMenuVisible(!isMenuVisible)}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            fontFamily: "Arial Rounded MT Bold",
            fontSstyle: "normal",
            fontWeight: "normal",
            fontSize: 30,
            textAlign: "center",
            marginBottom: 8,
          }}
        >
          {title}
        </div>
        {userAuthorizations.length > 0 && selectedPreschool && (
          <Dropdown
            preschools={userAuthorizations}
            selectedPreschool={selectedPreschool}
            onClick={changePreschool}
          />
        )}
      </div>
    </div>
  );
}
