import axios from "axios";
const convertUriToBase64 = async (uri) => {
  return new Promise((resolve, reject) => {
    axios
      .get("https://api.edchild.com/" + uri, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        let newImage = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        resolve(`data:image/jpeg;base64,${newImage}`);
      })
      .catch((err) => reject(err.response.data));
  });
};

export default convertUriToBase64;
