import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../newLayout/header/header";
import Carousel from "../../newLayout/carousel/Carousel";
// import Loading from "../../newLayout/carousel/Loading";
import HomeScreenButtons from "../../newLayout/homeScreenButtons/HomeScreenButtons";
import moment from "moment";
import useResizeWindow from "../../../hooks/useResizeWindow";

const PrivateHome = () => {
  const [todaysActivities, setTodaysActivities] = useState(null);

  const windowDimensions = useResizeWindow();

  const plannedActivities = useSelector(
    (state) => state.preschoolData.plannedActivities
  );
  const pedagogycalAreas = useSelector((state) => state.pedagogy.pedagogy);

  const { selectedPreschool } = useSelector((state) => state.siteVar);

  useEffect(() => {
    setTodaysActivities(
      plannedActivities.filter(
        (act) =>
          moment(act.plannedDate).format("YYYY-MM-DD") ===
          moment().format("YYYY-MM-DD")
      )
    );
  }, [plannedActivities]);

  const layout =
    windowDimensions.width > windowDimensions.height ? "landscape" : "portrait";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        maxWidth: layout === "portrait" ? 600 : 1200,
        height: "100vh",
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: 16,
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <Header title={selectedPreschool ? selectedPreschool.name : "..."} />
          {/* 5/7 Förskolor planerat aktiviteter för idag */}
        </div>
        <div style={{ margin: 8, marginLeft: 24, textAlign: "left" }}>
          Planerade aktiviteter idag på vår förskola:
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: layout === "portrait" ? "column" : "row",
            flex: 1,
            justifyContent: "center",
            maxHeight: layout !== "portrait" && 600,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <Carousel
              items={todaysActivities}
              selectedPreschool={selectedPreschool}
              pedagogycalAreas={pedagogycalAreas}
            />
          </div>

          <div
            style={{
              display: "flex",
              height: "100vw",
              maxHeight: 600,
              maxWidth: 600,
            }}
          >
            <HomeScreenButtons plannedActivities={plannedActivities} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateHome;
