import React from "react";
import Delete from "../../../assets/Delete";
import axios from "axios";
import { getPreschoolEmployees } from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";

export default function SectionEmployees({ section, employees }) {
  const dispatch = useDispatch();
  const { employementsDateSpan, selectedPreschool } = useSelector(
    (state) => state.siteVar
  );

  const onDelete = (employee) => {
    const confirm = prompt("Skriv medarbetarens namn för att radera den", "");
    if (confirm === employee.name) {
      axios
        .delete(`/v2/employement/${selectedPreschool._id}/${employee._id}`)
        .then((res) => {
          alert(`${employee.name} borttagen`);
          dispatch(
            getPreschoolEmployees(
              selectedPreschool._id,
              employementsDateSpan.dateFrom,
              employementsDateSpan.dateTo
            )
          );
        })
        .catch((err) => alert(err));
    } else {
      if (confirm !== null) {
        alert("Medarbetarens namn stämmer inte med vad du skrev");
      }
    }
  };
  return (
    <div style={{ marginBottom: 10, padding: 15 }}>
      <div style={{ fontWeight: "bold" }}>{section.name}</div>
      {employees
        .filter((employee) => employee.organisation === section._id)
        .map((employee) => (
          <div
            key={employee._id}
            style={{
              height: 40,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#FCF8DB",
              padding: 8,
              marginTop: 8,
              borderRadius: 10,
            }}
          >
            <div>
              {employee.name} {/*employee.employeeId*/}
              {employee.validTo}
            </div>
            <div onClick={() => onDelete(employee)}>
              <Delete />
            </div>
          </div>
        ))}
    </div>
  );
}
