import { SET_ROLES, RESET_STORE } from "../actions/types";

const initialState = [];
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ROLES:
      return action.payload;
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
