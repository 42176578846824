import React from "react";
import Close from "../../../assets/Close";
import MenuItem from "./MenuItem";
import { logoutUser } from "../../../store/actions";
import Logout from "../../../assets/Logout";
import { useDispatch, useSelector } from "react-redux";
import User from "../../../assets/User";
import useResizeWindow from "../../../hooks/useResizeWindow";

export default function MenuExtended({ onClickClose }) {
  const windowDimensions = useResizeWindow();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const width = windowDimensions.width;
  const layout =
    windowDimensions.width > windowDimensions.height ? "landscape" : "portrait";

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: width > 600 && layout === "portrait" ? 8 : 0,
        width: "85vw",
        zIndex: 1000,
        backgroundColor: "rgba(252, 248, 219, 1)",
        maxWidth: 300,
        borderBottomLeftRadius: 10,
        //borderTopLeftRadius: 10,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: 12,
          paddingRight: 12,
          height: "195px",
          backgroundColor: "rgba(253, 185, 19, 0.5)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 82,
              height: 82,
              borderRadius: 41,
              backgroundColor: "white",
              marginLeft: 30,
              marginTop: 15,
              backgroundColor: "rgba(252, 248, 219, 1)",
              overflow: "hidden",
            }}
          >
            {user.avatar.small ? (
              <img
                src={`https://api.edchild.com/${user.avatar.small.path}`}
                style={{ width: 82, height: 82, objectFit: "cover" }}
              />
            ) : (
              <User size="50px" />
            )}
          </div>
          <div style={{ cursor: "pointer" }}>
            <Close onClick={onClickClose} />
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            paddingLeft: 30,
            paddingBottom: 20,
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          {user.name}
        </div>
      </div>
      <div>
        <MenuItem text="Min profil" linkType="int" link="/profile" />
        <MenuItem text="Notifikationer" linkType="int" link="/notifications" />
        <MenuItem
          text="Om edChild"
          linkType="external"
          link="https://www.edchild.com/kontakt"
        />
        <MenuItem
          text="Användarvillkor"
          linkType="external"
          link="https://www.edchild.com/fokusforskola/villkor"
        />
        <MenuItem text="Support" linkType="mail" link="support@edchild.com" />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 30,
          textAlign: "left",
          marginBottom: 22.5,
          marginTop: 56,
          fontSize: 14,
          cursor: "pointer",
        }}
        onClick={() => dispatch(logoutUser())}
      >
        <Logout /> Logout
      </div>
    </div>
  );
}
