import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
import Footer from "../components/layout/public/Footer";
//import Header from "../components/layout/public/Header";

export const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  };
  return (
    <Route
      {...rest}
      component={(props) =>
        !isAuthenticated ? (
          <div style={containerStyle}>
            {/* <Header /> */}
            <Footer />
            <Component {...props} />
          </div>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

export default PublicRoute;
